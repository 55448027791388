import React from 'react'
import BackendCall from '../../com/BackendCall'
import BookMarkFreeIcon from '../../components/icons/jsx/BookMarkFreeIcon'
import BookMarkUsedIcon from '../../components/icons/jsx/BookMarkUsedIcon'

const BookMarkWidget = (props) => {
    const {
        saved = false,
        pub_id,
        _type
    } = props

    const [_saved, setSaved] = React.useState(saved)
    const toggleSaved = (e) => {
        e.stopPropagation();

        if (_saved) {
            switch (_type) {
                case 'embedded-ws':
                    new BackendCall(
                        () => { }
                    ).unsaveEmbeddedWS(pub_id)
                    break;
                case 'embedded-nodes':
                    new BackendCall(
                        () => { }
                    ).unSaveEmbeddedNode(pub_id)
                    break;
                default:
                    console.warn('type-not-found');
                    break;
            }
            setSaved(false)
        } else {
            switch (_type) {
                case 'embedded-ws':
                    new BackendCall(
                        () => { }
                    ).saveEmbeddedWS(pub_id)
                    break;
                case 'embedded-nodes':
                    new BackendCall(
                        () => { }
                    ).saveEmbeddedNode(pub_id)
                    break;
                default:
                    console.warn('type-not-found', _type);
                    break;
            }
            setSaved(true)
        }
    }

    const Lang = window.Lang
    return (
        <div
            className='bookmark-cnt'
            title={(_saved) ? Lang.lbl('UNSAVED_PUBLICATION') : Lang.lbl('SAVED_PUBLICATION')}
        >
            <button onClick={toggleSaved}>
                {(_saved) ? <BookMarkUsedIcon /> : <BookMarkFreeIcon />}
            </button>
        </div>
    )
}

export default BookMarkWidget