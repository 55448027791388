import '../../styles/pages/Home.scss'
import '../../styles/common.scss';

import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BackendCall from '../../com/BackendCall';
import ConfigApplet from './ConfigApplet';
import { IconSearch } from '../../components/icons/jsx/IconSearch'
import OwnWs from './OwnWs';
import InvitedWs from './InvitedWs';
import Publications from './Publications';
import TagDisplay from './TagsDisplay';
import Logo from './Logo';
import CustomWidgets from './CustomWidgets';

const Home = (props) => {
    const {
        active_workspace,
        set_workspaces,
        own,
        invited,
    } = props
    const [ws_fetched, setWsFetched] = React.useState(false)
    const [filter_by, setFilterBy] = React.useState('')

    const Lang = window.Lang

    React.useEffect(() => {
        // traemos los espacios de trabajo
        var api = new BackendCall((d) => {
            set_workspaces(d);
            setWsFetched(true);
        });
        api.workspacesGetAll();

        // guardamos si hay una publicacion para guardar
        // que se halla establecido para guardar antes de que el usuario
        // inicie sesion
        if (window.localStorage.publication_4_save)
            new BackendCall(() => {
                window.localStorage.removeItem('publication_4_save')
            })
                .saveEmbedded(window.localStorage.publication_4_save)

        if (
            window.localStorage.portal &&
            window.localStorage.portalUrl
        )
            new BackendCall(() => {
                window.localStorage.removeItem('portal')
            }).setCustomUserConfig(
                'portal',
                window.localStorage.portal
            )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!ws_fetched) return null;

    return (
        <div className="home">
            <div className="bar">
                <Logo />
                <div className="flex">
                    <input
                        type="text"
                        className="shadow"
                        style={{
                            paddingRight: "38px",
                        }}
                        value={filter_by}
                        onChange={(e) => setFilterBy(e.currentTarget.value.trim())}
                        placeholder={Lang.lbl('TOOLTIP_SEARCH_HERE')}
        
                    />
                    {(filter_by.length) ?
                        <span
                            onClick={() => setFilterBy('')}
                            style={{
                                position: 'relative',
                                marginLeft: "-34px",
                                padding: "5px",
                                paddingRight: "13px",
                                fontSize: "19px",
                                alignSelf: "center",
                                fontFamily: "monospace",
                                background: "var(--background-main)",
                                cursor: "pointer",
                            }}
                        >x</span>
                        :
                        <IconSearch
                            style={{
                                marginLeft: "-42px",
                                paddingRight: "11px"
                            }}
                        />
                    }
                </div>
                <ConfigApplet />
            </div>
            <TagDisplay
                onClick={v => setFilterBy(v)}
                filter_by={filter_by}
            />
            <CustomWidgets />
            <OwnWs
                active_workspace={active_workspace}
                own={own}
                filter_by={filter_by}
            />
            <InvitedWs
                invited={invited}
                active_workspace={active_workspace}
                filter_by={filter_by}
            />
            <Publications
                filter_by={filter_by}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        active_workspace: state.workspace_context.active_workspace,
        own: state.workspace_context.workspaces.own,
        invited: state.workspace_context.workspaces.invited,
        ui: state.ui,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        set_workspaces: (data) => {
            dispatch({ type: 'SET_WORKSPACES', data: data })
        }
    }
}

const Contenedor = withRouter(
    connect(mapStateToProps, mapDispatchToProps, undefined, { pure: false })(Home)
);

export default Contenedor