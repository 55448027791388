const INTIAL_STATE = {
    app: {
        initial_fetch: false,
        user_id: '',
        qty_nodes: false,
        qty_file_byte_used: false,
    },
    nodes_context: {
        fetched: false,
        search_tms: false,
        nodes_select: [],
        nodes_hide: [],
        node_active: '',
        node_active_title: '',
        force_node_update_id: null,
        page: 1,
        nodes: [],
        edges: [],
        manual: false,
        louvain: false,
        center_node_id: null,
        selection_mode_activated: false,
    },
    public_data: {
        static: true,
        visualization_flex: false,
    },
    search_context: {
        node_context: {
            search_string: '',
            recommend_searches: [],
            add_to_last_search: false,
            create_cluster: false,
            level: 0,
        }
    },
    workspace_context: {
        active_workspace: '',
        active_workspace_name: '',
        active_workspace_is_own: true,
        role: {},
        workspaces: {
            own: [],
            invited: [],
        },
        schemes: [],
        chatbot: null,
        default_search: null
    },
    current_embedded_ws: {
        saved: null,
    },
    msj_stack: [],
    wrn_stack: [],
    ui: {
        navigation_bar: {
            collapse: true,
            filter_text: '',
            maximized: false,
            expanded_recommended: [],
            recommended_fetched_yet: [],
            recommended_change_scheme: [],
            expanded_recommended_more: [],
        },
        grid: {
            select_mode: false,
        },
        network: {
            quick_select_mode: false,
        },
        node_editor: {
            related_with: null,
            related_with_name: null,
            related_scheme_id: null,
            related_with_selected: false,
        }
    }
}

window.state = INTIAL_STATE;

export function neuralActionsApp(state = INTIAL_STATE, action) {
    switch (action.type) {
        case "SET_FIRST_FETCH_TO_FALSE":
            return set_first_fetch_to_false(state, action);
        case "UPDATE_NODES_FROM_SEARCH":
            return update_nodes_from_search(state, action);
        case "SWITCH_SELECT_NODE":
            return switch_select_node(state, action);
        case "FORCE_NODE_SELECT":
            return force_node_select(state, action);
        case "FORCE_NODE_UNSELECT":
            return force_node_unselect(state, action);
        case "SELECT_ALL_NODES":
            return select_all_nodes(state);
        case "DESELECT_ALL_NODES":
            return deselect_all_nodes(state);
        case "ONCHANGE_SEARCH_TEXT":
            return onchange_search_text(state, action);
        case "SET_WORKSPACES":
            return set_workspaces(state, action);
        case "INITIAL_FETCH":
            return initial_fetch(state, action);
        case 'SET_NEW_SEARCH_STRING':
            return set_new_search_string(state, action);
        case 'DESELECT_NODE':
            return deselect_node(state, action);
        case 'UPDATE_NODE':
            return update_node(state, action);
        case 'NEW_NODE':
            return new_node(state, action);
        case 'ADD_NEW_EDGE':
            return add_new_edge(state, action);
        case 'DELETE_NODE':
            return delete_node(state, action);
        case 'DELETE_EDGE':
            return delete_edge(state, action);
        case 'CLEARLASTSEARCH':
            return clearLastSearch(state, action);
        case 'GET_MORE_NODES':
            return get_more_nodes(state, action);
        case 'TOGLE_OFF_PHYSICS':
            return togle_off_physics(state, action);
        case 'TOGLE_ON_LOUVAIN':
            return togle_on_louvain(state, action);
        case 'CENTER_NODE':
            return center_node(state, action);
        case 'POPNOTIFICATION':
            return popNotification(state, action);
        case 'PUSHNOTIFICATION':
            return pushNotification(state, action);
        case 'SET_PUBLIC_DATA':
            return set_public_data(state, action);
        case 'GET_NEW_SCHEME':
            return get_new_scheme(state, action);
        case 'UPDATE_SCHEME':
            return update_scheme(state, action);
        case 'SET_LEVEL_SEARCH':
            return set_level_search(state, action);
        case 'TOGGLE_COLLAPSE_NAVIGATION_BAR':
            return toggle_collapse_navigation_bar(state, action);
        case 'TOGGLE_MAXIMIZED_NAVIGATION_BAR':
            return toggle_maximized_navigation_bar(state, action);
        case 'SET_NAVIGATION_BAR_FILTER':
            return set_navigation_bar_filter(state, action);
        case 'UPDATE_NAVIGATION_EXPANDED_RECOMMENDED':
            return update_navigation_expanded_recommended(state, action);
        case 'UPDATE_RECOMMENDED_FETCHED_YET':
            return update_recommended_fetched_yet(state, action);
        case 'UPDATE_RECOMMENDED_CHANGE_SCHEME':
            return update_recommended_change_scheme(state, action);
        case 'UPDATE_EXPANDED_RECOMMENDED_MORE':
            return update_expanded_recommended_more(state, action);
        case 'UPDATE_NODES_HIDE':
            return update_nodes_hide(state, action);
        case 'TOGGLE_SELECTION_MODE':
            return toggle_selection_mode(state, action);
        case 'CLOSE_WARNING_NOTIFICATION':
            return close_warning_notification(state, action);
        case 'GRID_SELECT_MODE_SWITCH':
            return grid_select_mode_switch(state, action);
        case 'NETWORK_QUICK_SELECT_MODE_SWITCH':
            return network_quick_select_mode_switch(state, action);
        case 'SET_AS_SELECT_ONLY_THE_ACTIVE_NODE':
            return set_as_select_only_the_active_node(state, action);
        case 'SET_NODE_EDITOR_CREATED_RELATED_WITH':
            return set_node_editor_created_related_with(state, action);
        case 'CLEAR_NODE_EDITOR_CREATED_RELATED_WITH':
            return clear_node_editor_created_related_with(state, action);
        case 'SET_CURRENT_EMBEDDED_WS_DATA':
            return set_current_embedded_ws_data(state, action);
        default:
            return state
    }
}

function set_first_fetch_to_false(state, action) {
    state.app.initial_fetch = false
    return state
}

function toggle_selection_mode(state, action) {
    state.nodes_context.selection_mode_activated = !state.nodes_context.selection_mode_activated;
    //state.nodes_context.nodes_select = state.nodes_context.node_active===""? [] : [state.nodes_context.node_active] 
    return state;
}

function update_nodes_from_search(state, action) {
    const { nodes, edges, page, tms, chatbot } = action;

    state.nodes_context.nodes = nodes;
    state.nodes_context.force_node_update_id = null;
    state.nodes_context.edges = edges;
    state.nodes_context.page = page;
    state.nodes_context.fetched = true;
    state.nodes_context.search_tms = tms;
    state.nodes_context.nodes_hide = [];
    state.workspace_context.chatbot = chatbot;

    if (state.nodes_context.nodes.length === 0) {
        pushInternalNotification('NO_RESULT', 3500, state)
    }
    return state;
}

function switch_select_node(state, action) {
    var {
        nodes_select = [], node_active,
        node_active_title, node_active_content
    } = state.nodes_context;
    const { nodes = [] } = action.event;

    if (!state.nodes_context.selection_mode_activated) {
        // si esta el modo quick selec activo 
        // permite seleccionar aun con el el modo selec no activo
        // para permitir seleccionar nodos en herramientas que necesitan
        // multiple seleccion como crear nodos
        nodes_select = (state.ui.network.quick_select_mode && nodes[0]) ? [nodes[0], ...nodes_select] : nodes;
        node_active = nodes_select[0];
        node_active_title = nodes.node_name;
        node_active_content = nodes.content;
    }

    else if (nodes.length > 0) {
        if (nodes_select.includes(nodes[0])) {
            //con modo seleccion on
            if (state.nodes_context.nodes_hide.includes(nodes[0])) {
                // Si el nodo esta activo no se hace nada
            }
            // Caso 0 si es el activo deseleccionar
            // todos los casos son iguales ver https://git.democrm.com.ar/KunanPymes/NeuralActions/frontend/-/issues/125
            // else if (node_active === nodes[0]) {
            else {
                node_active = '';
                nodes_select = nodes_select.filter(i => i !== nodes[0]);
                node_active_title = '';
                node_active_content = '';

                // deshabilitado ver https://git.democrm.com.ar/KunanPymes/NeuralActions/frontend/-/issues/125
                // } else {
                // Caso 1 si no es el activo seleccionar como tal

                // node_active = nodes[0];
                // let n = state.nodes_context.nodes.filter(i => i.node_id === nodes[0])[0];
                // node_active_title = n.node_name;
                // node_active_content = n.content;
            }

        } else {
            //modo seleccion off ó no estaba seleccionado
            // Caso 2 selecionar y activar
            nodes_select.push(nodes[0])
            node_active = nodes[0]
            let n = state.nodes_context.nodes.filter(i => i.node_id === nodes[0])[0];
            node_active_title = n.node_name;
            node_active_content = n.content;
        }
    } else {
        // Deshabilitado
        // Caso 3 no pulso ningun nodo deseleccionar todo
        // nodes_select = [];
        // node_active = '';
        // node_active_title = '';
        // node_active_content = '';
    }

    state.nodes_context.nodes_select = nodes_select;
    state.nodes_context.node_active = node_active;
    state.nodes_context.node_active_title = node_active_title;
    state.nodes_context.node_active_content = node_active_content;

    return state;
}

function force_node_select(state, action) {
    var id = action.id;
    if (
        !state.nodes_context.nodes_select.includes(id) &&
        !state.nodes_context.nodes_hide.includes(id)
    ) {
        state.nodes_context.nodes_select.push(id);
    }

    if (!state.nodes_context.nodes_hide.includes(id)) {
        state.nodes_context.node_active = id;
    } else {
        state.nodes_context.node_active = '';
    }

    return state;
}

function force_node_unselect(state, action) {
    var id = action.id;
    state.nodes_context.nodes_select = state.nodes_context.nodes_select.filter(i => i !== id);
    state.nodes_context.node_active = ''
    return state;
}

function select_all_nodes(state) {
    var { nodes_select = [], nodes = [] } = state.nodes_context;
    nodes_select = nodes.map(i => i.node_id);

    state.nodes_context.nodes_select = nodes_select;
    state.nodes_context.node_active = '';

    return state;
}

function deselect_all_nodes(state) {
    state.nodes_context.nodes_select = [];
    state.nodes_context.node_active = '';

    return state;
}

function onchange_search_text(state, action) {
    state.search_context.node_context.search_string = action.value;
    return state;
}

function set_workspaces(state, action) {
    const { data } = action;
    state.workspace_context.active_workspace = data.active_workspace_id;
    state.workspace_context.workspaces.own = data.own;
    state.workspace_context.workspaces.invited = data.invited;
    return state;
}

function initial_fetch(state, action) {
    state.app.initial_fetch = true;
    const {
        last_searchs,
        active_workspace,
        user_id,
        qty_nodes,
        qty_file_byte_used,
        product_restrictions
    } = action;
    state.app.user_id = user_id;
    state.app.qty_nodes = qty_nodes;
    state.app.qty_file_byte_used = qty_file_byte_used; 
    
    // verificamos si los nodos estan cerca del limite
    if (product_restrictions && product_restrictions["max-nodes"]) {
        if (product_restrictions["max-nodes"] < qty_nodes) {
            state = pushWarningNotification('node_limit_exceeded', state)
        } else if ((product_restrictions["max-nodes"] - 40) < qty_nodes) {
            // Los creados estan cerca de superar o superaron los nodos posibles
            state = pushWarningNotification('node_limit', state)
        }
    }

    const searchs = last_searchs.map(i => i.string_search);

    if (last_searchs.length === 0) {
        state.search_context.node_context.recommend_searches = [];
        state.workspace_context.active_workspace = active_workspace;
        return state;
    }

    if(active_workspace.default_search) {
        state.workspace_context.default_search = active_workspace.default_search
        state.search_context.node_context.search_string = active_workspace.default_search;
    } else {
        state.search_context.node_context.search_string = '+';
    }


    if (searchs.length === 1 && searchs[0].string_search === "") {
        state.search_context.node_context.recommend_searches = [];
    } else {
        state.search_context.node_context.recommend_searches = searchs;
    }

    state.workspace_context.active_workspace = active_workspace.id;
    state.workspace_context.active_workspace_name = active_workspace.name;
    state.workspace_context.active_workspace_is_own = active_workspace.is_own;
    state.workspace_context.role = active_workspace.role;
    state.workspace_context.schemes = active_workspace.schemes;
    state.workspace_context.chatbot = active_workspace.chatbot;
    // pushInternalNotification('HI', 3500, state)
    return state;
}

function set_new_search_string(state, action) {
    const { search_str } = action;
    state.search_context.node_context.search_string = search_str;
    state.nodes_context.fetched = false;
    state.nodes_context.search_tms = false;
    state.nodes_context.nodes_select = [];
    state.nodes_context.node_active = '';
    state.nodes_context.node_active_title = '';
    state.nodes_context.page = 1;
    state.nodes_context.nodes = [];
    state.nodes_context.edges = [];
    return state;
}

function deselect_node(state, action) {
    const { node_id } = action;
    var old_selection = state.nodes_context.nodes_select;
    var new_selection = old_selection.filter(el => el !== node_id)
    state.nodes_context.nodes_select = new_selection;
    return state;
}

function update_node(state, action) {
    const { node_id, node_name, node_cover, hue, size, scheme_id } = action;
    var nodes = state.nodes_context.nodes;

    nodes.forEach(i => {
        if (i.node_id === node_id) {
            i.node_name = node_name;
            i.node_cover = node_cover;
            i.hue = hue;
            i.size = size;
            i.scheme_id = scheme_id;
        }
    })

    state.nodes_context.force_node_update_id = node_id;
    state.nodes_context.node_active_title = node_name;

    return state;
}

function new_node(state, action) {
    const { node_id, node_name, node_cover, hue, scheme_id, size } = action;
    state.nodes_context.nodes.push(
        { node_id, node_name, node_cover, hue, scheme_id, size }
    );

    //state.nodes_context.node_active = node_id;
    //state.nodes_context.nodes_select.push(node_id);
    //state.nodes_context.node_active_title = node_name;

    return state
}

function add_new_edge(state, action) {
    const { edge_id, node_a, node_b, edge_type, edge_name } = action;
    state.nodes_context.edges.push({
        edge_id: edge_id,
        node_a: node_a,
        node_b: node_b,
        edge_type: edge_type,
        edge_name: edge_name
    })
    return state;
}

function delete_node(state, action) {
    const { node_id } = action;

    let new_nodes = state.nodes_context.nodes.filter(node => node.node_id !== node_id);
    state.nodes_context.nodes = new_nodes;

    var new_nodes_select = state.nodes_context.nodes_select.filter(node => node !== node_id)
    state.nodes_context.nodes_select = new_nodes_select;

    if (state.nodes_context.force_node_update_id === node_id)
        state.nodes_context.force_node_update_id = null

    if (state.nodes_context.node_active === node_id) {
        state.nodes_context.node_active = '';
        state.nodes_context.node_active_title = '';
    }

    return state;
}

function delete_edge(state, action) {
    const edge_id = action.edge_id

    var new_edges = state.nodes_context.edges.filter(edge => edge.edge_id !== edge_id);
    state.nodes_context.edges = new_edges;

    return state;
}

function clearLastSearch(state) {
    state.nodes_context.search_tms = false;
    state.nodes_context.page = 1;
    state.nodes_context.force_node_update_id = null;
    state.nodes_context.nodes_select = [];
    state.nodes_context.nodes = [];
    state.nodes_context.edges = [];
    state.nodes_context.node_active = '';
    state.nodes_context.node_active_title = '';
    state.search_context.node_context.search_string = '+';
    state.nodes_context.fetched = false;
    state.nodes_context.search_tms = false;

    return state;
}

function get_more_nodes(state, action) {
    const { nodes, edges } = action.data;

    const new_nodes = nodes;
    const exist_nodes = state.nodes_context.nodes;
    const filter_nodes = new_nodes.filter(nn => !exist_nodes.map(i => i.node_id).includes(nn.node_id))
    // Remove duplicates
    const unique_nodes = filter_nodes.filter((n, i, a) => a.findIndex(e => e.node_id === n.node_id) === i);
    unique_nodes.map(n => state.nodes_context.nodes.push(n));

    const new_edges = edges;
    const exist_edges = state.nodes_context.edges;
    const filter_edges = new_edges.filter(i => !exist_edges.map(e => e.edge_id).includes(i.edge_id))
    // Remove duplicates
    const unique_edges = filter_edges.filter((n, i, a) => a.findIndex(e => e.edge_id === n.edge_id) === i);
    unique_edges.map(e => state.nodes_context.edges.push(e));

    return state;
}

function togle_off_physics(state, action) {
    state.nodes_context.manual = (state.nodes_context.manual) ? false : true;
    if (state.nodes_context.manual) {
        pushInternalNotification('PHYSICS_OFF', 1500, state)
    } else {
        pushInternalNotification('PHYSICS_ON', 1500, state)
    }
    return state;
}

function togle_on_louvain(state, action) {
    state.nodes_context.louvain = (state.nodes_context.louvain) ? false : true;
    if (state.nodes_context.louvain) {
        pushInternalNotification('LOUVAIN_ON', 1500, state)
    } else {
        pushInternalNotification('LOUVAIN_OFF', 1500, state)
    }
    return state;
}

function center_node(state, action) {
    const { id } = action;
    if (!state.nodes_context.nodes_hide.includes(id)) {
        state.nodes_context.center_node_id = id;
    }
    return state;
}

function popNotification(state, action) {
    const { notification_id } = action;

    var new_stack = state.msj_stack.filter(i => i.id !== notification_id);
    state.msj_stack = new_stack;
    return state;
}

function pushInternalNotification(msj, timeout, state) {
    if (typeof msj !== 'string') {
        console.error('not valid msj');
        return state;
    }
    if (typeof timeout !== 'number') {
        console.error('not valid timeout');
        return state;
    }
    if (!state.msj_stack.map(i => i.msj).includes(msj)) {
        state.msj_stack.push({ id: Math.random(), msj: msj, timeout: timeout })
    }

    return state
}

function pushWarningNotification(warning_id, state) {
    if (typeof warning_id !== 'string') {
        console.error('not valid warning type')
    }

    switch (warning_id) {
        case 'node_limit_exceeded':
        case 'node_limit':
            state.wrn_stack.push({ id: warning_id })
            break;
        default:
            console.error(warning_id + ' is not a valid warning_id')
            break;
    }
    return state;
}

function close_warning_notification(state, action) {
    const { warning_id } = action
    if (typeof warning_id !== 'string') {
        console.error('not valid warning type')
    }

    const up_wrn_stack = state.wrn_stack.filter(i => i.id !== warning_id)

    state.wrn_stack = up_wrn_stack

    return state;
}

function pushNotification(state, action) {
    const { msj, timeout } = action
    if (typeof msj !== 'string') {
        console.error('not valid msj');
        return state;
    }
    if (typeof timeout !== 'number') {
        console.error('not valid timeout');
        return state;
    }

    state.msj_stack.push({ id: Math.random(), msj: msj, timeout: timeout })
    return state;
}

function get_new_scheme(state, action) {
    const { id, name, disabled_for_recommendation = false } = action
    if (state.workspace_context.schemes.map(i => i.id).includes(id)) {
        return state;
    }

    state.workspace_context.schemes.push({ id, name, disabled_for_recommendation })
    return state;
}

function update_scheme(state, action) {
    const { id, name, disabled_for_recommendation = false } = action
    var { schemes } = state.workspace_context

    schemes = schemes.filter(i => i.id !== id)
    schemes.push({ id, name, disabled_for_recommendation })

    state.workspace_context.schemes = schemes

    return state;
}

function set_public_data(state, action) {
    const { staticq, visualization_flex } = action
    state.public_data.static = (staticq) ? true : false;
    state.public_data.visualization_flex = (visualization_flex) ? true : false;
    return state
}

function set_level_search(state, action) {
    const { level } = action;
    state.search_context.node_context.level = level
    return state
}

function toggle_collapse_navigation_bar(state, action) {
    state.ui.navigation_bar.collapse = !state.ui.navigation_bar.collapse
    if (!state.ui.navigation_bar.collapse) {
        state.ui.navigation_bar.maximized = false
    }
    return state
}

function toggle_maximized_navigation_bar(state, action) {
    state.ui.navigation_bar.maximized = !state.ui.navigation_bar.maximized
    return state
}

function set_navigation_bar_filter(state, action) {
    state.ui.navigation_bar.filter_text = action.filter_text
    return state
}

function update_navigation_expanded_recommended(state, action) {
    state.ui.navigation_bar.expanded_recommended = action.expanded_recommended
    return state
}

function update_recommended_fetched_yet(state, action) {
    state.ui.navigation_bar.recommended_fetched_yet = action.recommended_fetched_yet
    return state
}

function update_recommended_change_scheme(state, action) {
    state.ui.navigation_bar.recommended_change_scheme = action.recommended_change_scheme
    return state
}

function update_expanded_recommended_more(state, action) {
    state.ui.navigation_bar.expanded_recommended_more = action.expanded_recommended_more
    return state
}

function update_nodes_hide(state, action) {
    const { nodes_hide } = action

    state.nodes_context.nodes_hide = nodes_hide
    state.nodes_context.nodes_select = state.nodes_context.nodes_select.filter(i => !nodes_hide.includes(i))
    return state
}

function grid_select_mode_switch(state, action) {
    state.ui.grid.select_mode = !state.ui.grid.select_mode

    return state
}

function network_quick_select_mode_switch(state, action) {
    const { value } = action
    state.ui.network.quick_select_mode = (value) ? true : false

    return state
}

function set_as_select_only_the_active_node(state, action) {
    const { node_active } = state.nodes_context
    state.nodes_context.nodes_select = (node_active) ?
        [node_active] : []
    return state
}

function set_node_editor_created_related_with(state, action) {
    const { node_id, node_name, scheme_from_id } = action
    state.ui.node_editor.related_with = node_id
    state.ui.node_editor.related_with_name = node_name
    state.ui.node_editor.related_scheme_id = scheme_from_id
    return state
}

function clear_node_editor_created_related_with(state, action) {
    state.ui.node_editor.related_with = null
    state.ui.node_editor.related_with_name = null
    state.ui.node_editor.related_scheme_id = null
    return state
}

function set_current_embedded_ws_data(state, action) {
    const { saved = null } = action

    state.current_embedded_ws.saved = saved
    return state
}