import React from 'react'
import BackendCall from '../../com/BackendCall'
import { InfiniteLoaderBar } from '../../components/InfiniteLoaderBar'
import Item from './Item'
import getURL from '../../utils/getURL'
import InfiniteScroll from "react-infinite-scroll-component";
import List from './List';

const Publications = (props) => {
    const {
        filter_by,
    } = props

    const { Lang } = window
    const [loadingWSPub, setLoadingWSPub] = React.useState(true)
    const [loadingNodesPub, setLoadingNodesPub] = React.useState(true)
    const [publications, setPublications] = React.useState([])
    const [page, setPage] = React.useState(1);
    const [hasMoreWSPubPages, setHasMoreWsPubPages] = React.useState(true)
    const [hasMoreNodesPubPages, setHasMoreNodesPubPages] = React.useState(true)
    const [seeSavedPublications, setSavePublications] = React.useState(false)

    // Carga inicial o cuando se empieza a buscar
    React.useEffect(() => {
        initialLoading(filter_by)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter_by])

    const addToPublicationPool = (new_publications) => {
        setPublications(oldPubs => {
            const filteredNewPubs = new_publications
                .filter(i => !oldPubs.map(i => i.id).includes(i.id))
            var tmp = [...oldPubs, ...filteredNewPubs]
            // se ordena por fecha de publicacion
            tmp.sort((a, b) => (
                new Date(b.date_entered) - new Date(a.date_entered)
            ))

            window.tmp = tmp
            return tmp
        })
    }

    const resetPublicationPool = () => {
        setPublications([])
    }

    const initialLoading = (filter_by) => {
        if (filter_by.length === 0) {
            setLoadingWSPub(true)
            setLoadingNodesPub(true)
            setPage(1)
            setHasMoreWsPubPages(true)
            setHasMoreNodesPubPages(true)
            resetPublicationPool()

            // Traemos de backend publicaciones de WS recientes
            new BackendCall(
                d => {
                    addToPublicationPool(d)
                    setLoadingWSPub(false)
                }
            ).getInternallWsPublicationsRecent(1)

            // Traemos de backend publicaciones de nodes recientes
            new BackendCall(
                d => {
                    addToPublicationPool(d)
                    setLoadingNodesPub(false)
                }
            ).getInternallNodesPublicationsRecent(1)
        }

        if (filter_by.length > 0) {
            setLoadingWSPub(true)
            setLoadingNodesPub(true)
            setPage(1)
            setHasMoreWsPubPages(true)
            setHasMoreNodesPubPages(true)
            resetPublicationPool()

            // Busqueda de publiaciones WS
            new BackendCall(
                d => {
                    if (d.length === 0)
                        setHasMoreWsPubPages(false)
                    addToPublicationPool(d)
                    setLoadingWSPub(false)
                }
            ).searchInternallWsPublications(filter_by, 1)

            // Busqueda de publiaciones Nodes
            new BackendCall(
                d => {
                    if (d.length === 0)
                        setHasMoreNodesPubPages(false)

                    addToPublicationPool(d)
                    setLoadingNodesPub(false)
                }
            ).searchInternallNodesPublications(filter_by, 1)
        }
    }

    const seePost = (pub) => {
        const id = pub.id
        if (pub.type === 'embedded-nodes') {
            const node_id = pub.node_id
            window.location.href = getURL('generate-node-embedded-link', { id, node_id, })
        }
        if (pub.type === 'embedded-ws')
            window.location.href = getURL('generate-embedded-link', { embedded_id: id })
    }

    // Funcion para añadir nuevas paginas
    const addPage = () => {
        const newPage = page + 1;

        // Traemos de backend otras paginas de publicaciones recientes
        if (filter_by.length === 0) {
            setPage(newPage)
            new BackendCall(
                d => {
                    if (d.length === 0)
                        setHasMoreWsPubPages(false);

                    addToPublicationPool(d)
                }
            ).getInternallWsPublicationsRecent(newPage)

            new BackendCall(
                d => {
                    if (d.length === 0)
                        setHasMoreNodesPubPages(false);

                    addToPublicationPool(d)
                }
            ).getInternallNodesPublicationsRecent(newPage)
        }

        // Busqueda de otras paginas de publiaciones
        if (filter_by.length > 0) {
            setPage(newPage)
            new BackendCall(
                d => {
                    if (d.length === 0)
                        setHasMoreWsPubPages(false);

                    addToPublicationPool(d)
                }
            ).searchInternallWsPublications(filter_by, newPage)

            new BackendCall(
                d => {
                    if (d.length === 0)
                        setHasMoreNodesPubPages(false)

                    addToPublicationPool(d)
                }
            ).searchInternallNodesPublications(filter_by, newPage)
        }
    }

    // Funcion para obtener publicaciones guardadas
    const getSavedPublications = () => {
        resetPublicationPool()
        setLoadingWSPub(true)
        setLoadingNodesPub(true)
        setSavePublications(true)

        new BackendCall(
            d => {
                d = d.map(it => {
                    const { id, name, cover, description, date_entered } = it.obj_data
                    return { id, name, saved: true, cover, description, date_entered, type: 'embedded-ws' }
                })

                addToPublicationPool(d)
                setLoadingWSPub(false)
            }
        ).getSavedEmbeddedWSIndex()

        new BackendCall(
            d => {
                d = d.map(it => {
                    const { id, name, cover, description, date_entered } = it.obj_data
                    return { id, name, saved: true, cover, description, date_entered, type: 'embedded-nodes' }
                })
                addToPublicationPool(d)
                setLoadingNodesPub(false)
            }
        ).getSavedEmbeddedNodesIndex()
    }

    if (loadingWSPub || loadingNodesPub) {
        return (
            <div
                className="cnt"
                style={{ minHeight: 'unset' }}
            >
                <div className="title">{Lang.lbl('SEARCHING_PUBLICATIONS')}</div>
                <InfiniteLoaderBar />
            </div>
        )
    }

    // Renderizado de publicaciones recientes
    if (!seeSavedPublications && publications.length) {
        return (
            <div className="cnt">
                <div className="title">
                    {(filter_by.length) ? Lang.lbl('FOUND_THESE_PUBLICATIONS') : Lang.lbl('RECENT_PUBLICATIONS')}
                    <button
                        style={{
                            textDecoration: 'underline',
                            fontSize: '16px',
                            position: 'absolute',
                            right: '16px',
                        }}
                        onClick={getSavedPublications}
                    >
                        {Lang.lbl('SEE_SAVED_PUBLICATION')}
                    </button>
                </div>
                <InfiniteScroll
                    dataLength={() => (publications.length)}
                    next={addPage}
                    hasMore={hasMoreWSPubPages && hasMoreNodesPubPages}
                    loader={<></>}
                >
                    {publications.map(pub => {
                        var cover_obj = null
                        try {
                            pub.cover && (cover_obj = JSON.parse(pub.cover))
                        } catch (e) {
                            console.error(e)
                            cover_obj = null
                        }
                        const cover = (cover_obj && cover_obj.src) ? '/' + cover_obj.src : null
                        let description = (pub.description) ? pub.description.slice(0, 81) : Lang.lbl('PUBLICATION_DESCRIPTION_IS_EMPTY')
                        return (
                            <Item
                                title={pub.name}
                                key={pub.id}
                                onClick={() => seePost(pub)}
                                cover={cover}
                                name={pub.name}
                                description={description}
                                saved={pub.saved}
                                id={pub.id}
                                _type={pub.type}
                                node_id={pub.node_id}
                            >
                                <div className="toolbar">
                                    <button
                                        className={"main rounded expanded"}
                                        onClick={() => seePost(pub)}
                                    >{Lang.lbl('SEE_PUBLICATION')}</button>
                                </div>
                            </Item>
                        )
                    })}
                </InfiniteScroll>
            </div>
        )
    }

    if (seeSavedPublications) {
        return (
            <div className="cnt">
                <div className="title">
                    {Lang.lbl('SAVED_PUBLICATIONS')}
                    <button
                        style={{
                            textDecoration: 'underline',
                            fontSize: '16px',
                            position: 'absolute',
                            right: '16px',
                        }}
                        onClick={() => {
                            setSavePublications(false)
                            initialLoading(filter_by)
                        }}
                    >
                        {Lang.lbl('SEE_PUBLICATIONS')}
                    </button>
                </div>
                <List>
                    {publications.map(pub => {
                        var cover_obj = null
                        try {
                            pub.cover && (cover_obj = JSON.parse(pub.cover))
                        } catch (e) {
                            console.error(e)
                            cover_obj = null
                        }
                        const cover = (cover_obj && cover_obj.src) ? '/' + cover_obj.src : null
                        let description = (pub.description) ? pub.description.slice(0, 81) : Lang.lbl('PUBLICATION_DESCRIPTION_IS_EMPTY')
                        return (
                            <Item
                                title={pub.name}
                                key={pub.id}
                                onClick={() => seePost(pub)}
                                cover={cover}
                                name={pub.name}
                                description={description}
                                saved={pub.saved}
                                id={pub.id}
                                _type={pub.type}
                                node_id={pub.node_id}
                            >
                                <div className="toolbar">
                                    <button
                                        className={"main rounded expanded"}
                                        onClick={() => seePost(pub)}
                                    >{Lang.lbl('SEE_PUBLICATION')}</button>
                                </div>
                            </Item>
                        )
                    })}
                </List>
            </div>
        )
    }

    // Cuando se busca y no encuentra publicaciones
    if (filter_by)
        return (
            <div
                className="cnt"
                style={{ minHeight: 'unset' }}
            >
                <div className="title">{Lang.lbl('NO_FOUND_PUBLICATIONS').replace('FILTER_BY', filter_by)}</div>
            </div>
        )

    return (
        <div
            className="cnt"
            style={{ minHeight: 'unset' }}
        >
            <div className="title">{Lang.lbl('THERE_IS_NOT_INTERNAL_PUBLICATIONS')}</div>
        </div>
    )
}

export default Publications