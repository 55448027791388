import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}
i18n
// learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  .use(LanguageDetector)
// connect with React
  .use(initReactI18next)
// for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    detection: options,
    fallbackLng: 'en', 
    supportedLngs: ['en', 'es'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
//*** call without param to use language detector
i18n.changeLanguage()
export default i18n;
