import React from 'react';

export const IconShare = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={(props.width) ? props.width : "38"}
            height={(props.width) ? props.width : "38"}
            version="1.1"
            viewBox="0 0 73.993 73.993"
        >
            <defs>
                <marker orient="auto" overflow="visible" refX="0" refY="0">
                    <path
                        fill="#ff0"
                        fillOpacity="1"
                        fillRule="evenodd"
                        stroke="#ff0"
                        strokeOpacity="1"
                        strokeWidth=".4pt"
                        d="M-4 0l-2 2 7-2-7-2 2 2z"
                    ></path>
                </marker>
                <marker orient="auto" overflow="visible" refX="0" refY="0">
                    <path
                        fill="#000"
                        fillOpacity="1"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeOpacity="1"
                        strokeWidth=".8pt"
                        d="M-10 0l-4 4L0 0l-14-4 4 4z"
                    ></path>
                </marker>
                <clipPath clipPathUnits="userSpaceOnUse">
                    <path
                        fill="#0f0"
                        fillOpacity="1"
                        stroke="none"
                        strokeDasharray="none"
                        strokeDashoffset="0"
                        strokeLinecap="round"
                        strokeLinejoin="bevel"
                        strokeMiterlimit="4"
                        strokeOpacity="1"
                        strokeWidth="2.365"
                        d="M-87.13 82.383H-36.882999999999996V120.335H-87.13z"
                        opacity="1"
                        paintOrder="stroke fill markers"
                    ></path>
                </clipPath>
            </defs>
            <g transform="translate(236.38 210.124)">
                <path
                    fillOpacity="1"
                    strokeWidth="1"
                    d="M-226.26-146.88c-.529-.26-1.203-.867-1.497-1.346-.513-.836-.532-1.754-.462-21.875l.073-21.001.88-.88c.484-.484 1.38-.996 1.994-1.138 1.35-.313 20.91-.363 20.91-.053 0 .12-1.187 1.403-2.64 2.849l-2.64 2.629h-13.234V-152.09h34.18v-6.99l2.707-2.694 2.706-2.695-.001 7.685c-.001 7.206-.037 7.744-.576 8.628-1.102 1.808-.547 1.764-21.988 1.757-17.558-.006-19.543-.053-20.412-.48zm9.366-11.578c0-.826.733-4.785 1.327-7.162 3.05-12.215 9.31-20.597 18.902-25.316 1.697-.835 4.12-1.817 5.385-2.182l2.3-.665v-3.223c0-1.772.097-3.222.215-3.222.119 0 4.124 3.91 8.9 8.688l8.684 8.687-8.684 8.688c-4.776 4.778-8.781 8.688-8.9 8.688-.118 0-.215-1.53-.215-3.401v-3.4l-1.311-.197c-.721-.108-2.37-.111-3.664-.006-8.277.671-15.878 4.973-21.165 11.98-1.585 2.099-1.774 2.317-1.774 2.042z"
                ></path>
            </g>
        </svg>
    )
}
