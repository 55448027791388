import React from 'react';

export const IconSearch = (props) => {
    return (
        <svg
            height="32px"
            version="1.1"
            viewBox="0 0 32 32"
            width="32px"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <path
                d="M19.427 21.427a8.5 8.5 0 1 1 2-2l5.585 5.585c.55.55.546 1.43 0 1.976l-.024.024a1.399 1.399 0 0 1-1.976 0l-5.585-5.585zM14.5 21a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13z"
                //fill={context.state.colorIcons}
                fillRule="evenodd"
            />
        </svg>
    )
}
