import React from 'react'
import { InfiniteLoaderBar } from '../../components/InfiniteLoaderBar';
import YouReachThePlanLimit from '../../components/YouReachThePlanLimit';
import { IconPlus } from '../../components/icons/jsx/IconPlus';
import BackendCall from '../../com/BackendCall';
import getURL from '../../utils/getURL';

export const WsAddBttn = (props) => {
    const { history, filter_by = '' } = props
    const [unable_to_create, setUnableToCreate] = React.useState(false)
    const [isLoading, setLoading] = React.useState(false)

    const createWs = () => {
        new BackendCall(
            d => {
                if (d.result) {
                    const uri = filter_by ? getURL('ws-new') + '?scheme-name=' + filter_by : getURL('ws-new')
                    history.push(uri)
                } else {
                    setUnableToCreate(true)
                }
                setLoading(false)
            },
            e => console.error(e)
        ).userCan('create-workspace')
    }

    return (
        <>
            <button
                className="new-ws rounded"
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if (!isLoading) {
                        setLoading(true)
                        createWs()
                    }
                }}
            >
                <IconPlus width='34' />
                {(isLoading) ? (
                    <div className="loader">
                        <InfiniteLoaderBar />
                    </div>
                ) : null}
            </button>
            {(unable_to_create) ?
                (
                    <YouReachThePlanLimit
                        callback={() => setUnableToCreate(false)}
                        context='ws'
                    />
                ) : null
            }
        </>
    )
}