import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';

// Utils
import Lang from './com/Lang';
import getURL from './utils/getURL';
import LoginTools from './utils/LoginTools';
import Main from './pages/Main';
import { MainLoading } from './components/MainLoading';
import BackendCall from './com/BackendCall';

// Dinamic load component
const NotLogedApp = (props) => {
    const Component = React.useMemo(() => (
        React.lazy(() => import('./pages/NotLogedApp'))
    ), [])

    return (<Component {...props} />)
}

// Este elemento api nos sirve para poder aplicar 
// parametrizaciones a usuarios de forma más sencilla
window.api = new BackendCall(d => console.log(d));

class App extends Component {
    state = {
        // con este flag forzamos a la app a descargar el idioma
        lang_ready: false,
        // con este flag forzamos a la app el estado de logeado del usuario
        validLogin: LoginTools.userIsLoged(),
        // con este flag forzamos a la app detectar las paginas publicas
        isPublic: window.location.pathname.includes('public') ? true : false,
        isALoginValidPage: window.location.pathname.split('/').filter(i => ['login', 'register', 'forgotpass'].filter(e => i === e).length > 0).length > 0
    }

    setValidLoginToTrue = () => {
        this.setState({ validLogin: true })
    }

    setValidLoginToFalse = () => {
        this.setState({ validLogin: false })
    }

    updateLang = () => {
        this.setState({ lang_ready: false });
        window.Lang = new Lang();
        window.Lang.getLang(
            () => {
                this.setState({ lang_ready: true });
            },
            (e) => console.error(e)
        )
    }

    componentDidMount() {
        // Es necesario que el usuario se inicie sesion?
        (LoginTools.userIsLoged()) ?
            this.setValidLoginToTrue() :
            this.setValidLoginToFalse()

        // Solicitar idioma
        this.updateLang()

        // Redireccion del usuario al login
        if (!this.state.isPublic && !this.state.isALoginValidPage) {
            if (!LoginTools.userIsLoged()) {
                let returnUrl = encodeURI(window.location.href.replace(window.location.origin, ''));
                this.props.history.push(getURL('login', { returnUrl }))
            }
        }
    }

    render() {
        const { isPublic, lang_ready } = this.state

        if (!lang_ready)
            return (<MainLoading />)
        else if (isPublic || !LoginTools.userIsLoged())
            return (
                <Suspense fallback={<MainLoading />}>
                    <NotLogedApp
                        key="notLogedApp"
                        setValidLoginToTrue={this.setValidLoginToTrue}
                        updateLang={this.updateLang}
                    />
                </Suspense>
            )

        return (
            <Main
                setValidLoginToFalse={this.setValidLoginToFalse}
                setValidLoginToTrue={this.setValidLoginToTrue}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state.nodes_context,
        workspace_context: state.workspace_context,
        initial_fetch_toggle: state.app.initial_fetch,
        navigation_bar_collapse: state.ui.navigation_bar.collapse,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const Contenedor = withRouter(
    connect(mapStateToProps, mapDispatchToProps, undefined, { pure: false })(App)
);

export default Contenedor;
