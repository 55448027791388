import React from 'react';
import ENV from '../env';

const handleCalls = (call) => {
    switch (call) {
        case 'getCurrURI':
            return window.location.pathname
        default:
            break;
    }
}

const PostMessages = () => {
    React.useEffect(() => {
        // usamos la api de messages para comunicarnos
        // con integraciones autorizadas que usan neuralactions
        // como iframe
        window.addEventListener('message', ev => {
            if (ENV.allowParentsOrigin.includes(ev.origin)) {
                ev.source.postMessage(handleCalls(ev.data), ev.origin);
            } else {
                return;
            }
        });
    }, [])

    // no renderizamos nada
    return (null)
}

export default PostMessages