import React from "react";

function CancelIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.width ? props.width : '38'} width={props.width ? props.width : '38'}
      style={(props && props.style) ? props.style : {}}
      viewBox="0 0 512 512"
    >
      <path
        strokeWidth="1"
        d="M496.921 424.647c19.999 19.998 19.999 52.396 0 72.394-9.919 10-23.038 14.959-36.157 14.959-13.119 0-26.206-5-36.19-14.999L255.98 328.494 87.401 496.961C77.402 507.041 64.3 512 51.196 512c-13.103 0-26.19-4.96-36.197-15.039C-5 476.963-5 444.565 14.999 424.567L183.626 255.94 14.999 87.393C-5 67.395-5 34.997 14.999 15 34.997-5 67.395-5 87.393 14.999L255.98 183.706 424.607 15.079c19.998-19.999 52.396-19.999 72.394 0 19.999 19.998 19.999 52.396 0 72.394L328.374 256.1z"
      ></path>
    </svg>
  );
}

export default CancelIcon;

