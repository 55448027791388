import BackendCall from "./BackendCall";

export default class Lang {
    valid_langs = ['es', 'en'];
    lang = [];

    getLang(getOk, getFail) {
        if (typeof getOk !== 'function' || typeof getFail !== 'function') {
            throw new Error("callback parameters must be a function")
        }

        var api = new BackendCall(lang => {
            this.lang = lang;
            getOk();
        }, getFail);

        var lang_user = '';
        if (window.localStorage.lang) {
            // Si se a guardado el idioma antes
            lang_user = window.localStorage.getItem('lang')
        } else {
            // Si no se a guardado el idioma antes
            lang_user = window.navigator.language.substring(0, 2);
            window.localStorage.setItem('lang', lang_user);
        }

        if (!this.valid_langs.includes(lang_user)) {
            lang_user = 'es'
            window.localStorage.setItem('lang', 'es');
        }
        api.LangView(lang_user);
    }

    lbl(label) {
        const d = this.lang.filter(i => i.lbl === label);
        if (d.length === 0) {
            console.error(label);
            throw new Error('Etiqueta indefinida');
        }
        return d[0].value;
    }
}
