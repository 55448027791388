import React, { useEffect, useRef } from "react"
import ReactDOM from 'react-dom';
import './Portal.scss'

export const Portal = (props) => {
    const el = useRef(document.createElement('div'));
    useEffect(() => {
        const portal = document.getElementById('portal');
        portal.classList.add('max');
        portal.appendChild(el.current);

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            portal.removeChild(el.current);
            portal.classList.remove('max');
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.children.length]);

    return ReactDOM.createPortal(props.children, el.current);
}

export const PortalDiv = () => <div id='portal'></div>;