import BackendCall from '../com/BackendCall';
import NeuralActionsApp from '../stores/NeuralActionsApp';

const SchemesTools = {}

SchemesTools.search_scheme_stack = []

SchemesTools.getSchemeName = (scheme_id) => {
    const { schemes } = NeuralActionsApp.getState().workspace_context

    var scheme_name = '';
    if (schemes.filter(s => s.id === scheme_id).pop()) {
        scheme_name = schemes.filter(s => s.id === scheme_id).pop().name
        if (scheme_name === 'djradxkfnf-x35gjm5hff') {
            scheme_name = window.Lang.lbl('SED_NAME_djradxkfnf_x35gjm5hff');
        }
    } else if (!SchemesTools.search_scheme_stack.includes(scheme_id)) {
        SchemesTools.retrieveScheme(scheme_id)
    }
    return scheme_name
}

SchemesTools.retrieveScheme = (scheme_id) => {
    const { active_workspace } = NeuralActionsApp.getState().workspace_context
    // momento de buscar esquema
    // por que el scheme es compartido
    // para evitar buscar mas de dos veces el mismo scheme
    SchemesTools.search_scheme_stack.push(scheme_id)

    var api = new BackendCall(
        d => {
            let name = d.name.replace(
                'djradxkfnf-x35gjm5hff',
                window.Lang.lbl('SED_NAME_djradxkfnf_x35gjm5hff')
            )

            if (d.obj_data.ws_id !== active_workspace)
                name = name + ' ' + window.Lang.lbl('SHARED_NODE')


            NeuralActionsApp.dispatch({
                type: 'GET_NEW_SCHEME',
                id: d.id,
                name: name
            })
        },
        e => console.error(e)
    )
    api.getScheme(scheme_id)
}

SchemesTools.retrieveAllSharedSchemes = () => {
    const { schemes } = NeuralActionsApp.getState().workspace_context
    const { nodes } = NeuralActionsApp.getState().nodes_context

    // Filtramos los id de los faltantes
    const shared_id = nodes.map(i => i.scheme_id).filter(i => !schemes.map(i => i.id).includes(i))
    // Filtramos los repetidos
    const uniq_id = [...new Set(shared_id)];

    uniq_id.forEach(i => SchemesTools.retrieveScheme(i))
}

SchemesTools.getSchemeByName = (scheme_name) => {
    const { schemes } = NeuralActionsApp.getState().workspace_context

    const scheme = schemes
        .filter(i => {
            if (
                i.name === 'djradxkfnf-x35gjm5hff' &&
                window.Lang.lbl('SED_NAME_djradxkfnf_x35gjm5hff') === scheme_name
            )
                return true
            else
                return i.name === scheme_name
        }).shift()

    if (scheme)
        return scheme
    else
        return false
}

SchemesTools.updateName = (scheme_id, name, ws_id, disabled_for_recommendation) => {
    const { active_workspace } = NeuralActionsApp.getState().workspace_context

    if (active_workspace === ws_id) {
        NeuralActionsApp.dispatch({
            type: 'UPDATE_SCHEME',
            id: scheme_id,
            name: name.replace(
                'djradxkfnf-x35gjm5hff',
                window.Lang.lbl('SED_NAME_djradxkfnf_x35gjm5hff')
            ),
            disabled_for_recommendation
        })
    }
}

SchemesTools.addNewScheme = (scheme_id, name, ws_id, disabled_for_recommendation) => {
    const { active_workspace } = NeuralActionsApp.getState().workspace_context

    if (active_workspace === ws_id) {
        NeuralActionsApp.dispatch({
            type: 'GET_NEW_SCHEME',
            id: scheme_id,
            name: name.replace(
                'djradxkfnf-x35gjm5hff',
                window.Lang.lbl('SED_NAME_djradxkfnf_x35gjm5hff')
            ),
            disabled_for_recommendation
        })
    }
}

SchemesTools.checkIsShared = (scheme_id) => {
    const { schemes } = NeuralActionsApp.getState().workspace_context

    if (schemes.filter(s => s.id === scheme_id).pop()) {
        const scheme_name = schemes.filter(s => s.id === scheme_id).pop().name
        if (scheme_name.includes(window.Lang.lbl('SHARED_NODE')))
            return true
        return false
    } else if (!SchemesTools.search_scheme_stack.includes(scheme_id)) {
        SchemesTools.retrieveScheme(scheme_id)
        return true
    }
}

window.SchemesTools = SchemesTools;
export default SchemesTools;
