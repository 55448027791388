import React from 'react'
import WsList from './WsList'

const InvitedWs = (props) => {
    const {
        invited,
        active_workspace,
        filter_by
    } = props

    const { Lang } = window
    const [nonResult, setNonResult] = React.useState(invited.length === 0)

    if (invited.length === 0) {
        return null
    }
    return (
        <div className="cnt"
            style={(nonResult) ? { minHeight: 'unset' } : {}}
        >
            {nonResult ?
                <div className="title">{Lang.lbl('NO_INVITED_WS_MATCH_THE_SEARCH').replace('FILTER_BY', filter_by)}</div> :
                <div className="title">{Lang.lbl('INVITED')}</div>
            }
            <WsList
                active_workspace={active_workspace}
                ws={invited}
                isOwn={false}
                filter_by={filter_by}
                onNonResult={() => setNonResult(true)}
                onResult={() => nonResult && setNonResult(false)}
            />
        </div>
    )
}

export default InvitedWs