import '../App.css';
import '../styles/inputs.scss'

import React, { Suspense } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Offline } from "react-detect-offline";
import { ThemeProvider } from '../contexts/ThemeContext';
import ENV from '../env';

//pages con preload
import { NotFound } from './NotFound';
import Home from './Home/Home';

// Utils
import FirstFetch from '../components/FirstFetch';
import { MainLoading } from '../components/MainLoading';
import { PortalDiv } from '../utils/Portal';

// Paginas que se cargan dinamicamente solo cuando son requeridas
const DataRoot = (props) => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./DataRoot'))
  ), [])

  return (<Component {...props} />)
}

const ActionsSchemeEdit = (props) => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./ActionsSchemeEdit'))
  ), [])

  return (<Component {...props} />)
}

const SchemeEdit = (props) => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./SchemeEdit'))
  ), [])
  return (<Component {...props} />)
}

const SchemeIndex = (props) => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./SchemeIndex'))
  ), [])
  return (<Component {...props} />)
}

const EmbeddedEditor = (props) => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./EmbeddedEditor/EmbeddedEditor'))
  ), [])
  return (<Component {...props} />)
}

const Config = (props) => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./Config'))
  ), [])
  return (<Component {...props} />)
}

const SetPass = (props) => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./SetPass'))
  ), [])
  return (<Component {...props} />)
}

const WsEdit = (props) => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./WsEdit'))
  ), [])
  return (<Component {...props} />)
}

const UserStatus = (props) => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./UserStatus'))
  ), [])
  return (<Component {...props} />)
}

const ThanksForSubscribed = (props) => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./ThanksForSubscribed'))
  ), [])
  return (<Component {...props} />)
}

const YourSubscritionIsCancelled = (props) => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./YourSubscritionIsCancelled'))
  ), [])
  return (<Component {...props} />)
}

const ProfilePicture = props => {
  const Component = React.useMemo(() => (
    React.lazy(() => import('./ProfilePicture'))
  ), [])
  return (<Component {...props} />)
}

const Main = () => {
  return (
    <>
      {ENV.PRODUCION ?
        (<Offline>
          <div className='offline warning'>{window.Lang.lbl('OFFLINE_WARNING')}</div>
        </Offline>) : null
      }
      <ThemeProvider>
        < div className="App" >
          <PortalDiv />
          <FirstFetch />
          <Suspense fallback={<MainLoading />}>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/config' component={Config} />
              <Route exact path='/config/profile-picture' component={ProfilePicture} />
              <Route exact path='/config/status' component={UserStatus} />
              <Route exact path='/config/subs/activated' component={ThanksForSubscribed} />
              <Route exact path='/config/subs/cancel' component={YourSubscritionIsCancelled} />
              <Route exact path='/setpass' component={SetPass} />
              <Route exact path='/ws/new/' component={WsEdit} />
              <Route exact path='/ws/:ws_id/edit/' component={WsEdit} />
              <Route exact path='/ws/:ws_id/scheme/' component={SchemeIndex} />
              <Route exact path='/ws/:ws_id/scheme/new' component={SchemeEdit} />
              <Route exact path='/ws/:ws_id/scheme/edit/:type_id' component={SchemeEdit} />
              <Route exact path='/ws/:ws_id/scheme/actions/:type_id' component={ActionsSchemeEdit} />
              <Route exact path='/ws/:ws_id/embedded' component={EmbeddedEditor} />
              <Route exact path='/:visualization/:tool?/:tool_param1?' component={DataRoot} />
              <Route component={NotFound} status={404} />
            </Switch>
          </Suspense>
        </div>
      </ThemeProvider >
    </>
  )
}
export default withRouter(Main);
