import React from 'react';

export const IconPlus = (props) => {
  return (
    <svg
      width={(props.width) ? props.width : 40}
      height={(props.width) ? props.width : 40}
      viewBox="0 0 10.583 10.583"
      version="1.1"
    >
      <path
        style={{
          fillOpacity: 1,
          strokeWidth: "0.01158665"
        }}
        d="M4.64 2.646V4.68H2.646v1.223H4.64v2.035h1.304V5.903h1.994V4.68H5.944V2.646z"
      />
    </svg>
  )
}
