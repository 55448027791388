import '../../styles/pages/Home.scss'

import React from 'react'
import { WsAddBttn } from './WsAddBttn';
import { WsToolBar } from './WsToolBar';
import WsItem from './WsItem';
import { withRouter } from 'react-router';

const WsList = (props) => {
    const {
        active_workspace,
        ws,
        isOwn,
        history,
        filter_by,
        disable_expanded = false,
        onNonResult,
        onResult,
    } = props
    const Lang = window.Lang
    const [expanded, setExpanded] = React.useState(disable_expanded)

    const ws_filtered = (filter_by) ? ws.filter(a => {
        let name = a.name.replace('personal_workspace_', Lang.lbl('PERSONAL_WORKSPACE')).toLowerCase()
        let tags = a.tags ? a.tags.toLowerCase() : ''
        return name.includes(filter_by.toLowerCase()) || tags.includes(filter_by.toLowerCase())
    }) : ws;

    const ws_to_render = (!expanded) ? (isOwn) ? ws_filtered.slice(0, 2) : ws_filtered.slice(0, 3) : ws_filtered

    if (ws_to_render.length === 0)
        onNonResult && typeof onNonResult === 'function' && onNonResult()

    if (ws_to_render.length > 0)
        onResult && typeof onResult === 'function' && onResult()

    return (
        <>
            <div className='ws-list'>
                {ws_to_render.map(ws => {
                    let active = (active_workspace === ws.id) ? 'active' : ''
                    let name = ws.name.replace('personal_workspace_', Lang.lbl('PERSONAL_WORKSPACE')).slice(0, 37)
                    let description = (ws.description) ? ws.description.slice(0, 100) : ''
                    let cover_obj = null
                    try {
                        ws.cover && (cover_obj = JSON.parse(ws.cover))
                    } catch (e) {
                        console.error(e)
                        cover_obj = null
                    }

                    let cover = (
                        ws.cover &&
                        !Array.isArray(cover_obj) &&
                        cover_obj.src
                    ) ? '/' + cover_obj.src : null

                    return (
                        <WsItem
                            title={(active_workspace === ws.id) ? Lang.lbl('THIS_IS_THE_CURRENT_WS') : ''}
                            active={active}
                            id={'i-' + ws.id}
                            key={ws.id}
                            cover={cover}
                            name={name}
                            ws_id={ws.id}
                            description={description}
                        >
                            <WsToolBar
                                isOwn={isOwn}
                                ws_id={ws.id}
                                role={(ws.role) ? ws.role : {}}
                                history={history}
                            />
                        </WsItem>
                    )
                })
                }
            </div>
            <div className="view_more">
                <div className='cnt'>
                    {(isOwn && ws_to_render.length && filter_by.length === 0) ?
                        <WsAddBttn history={history} /> :
                        null
                    }
                    {
                        (!expanded && ws_to_render.length < ws_filtered.length) ?
                            <button
                                className="rounded shadow"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setExpanded(true)
                                }}
                            >{Lang.lbl('VIEW_MORE_WS')}</button>
                            :
                            null
                    }
                </div>
            </div>
        </>
    )
}


export default withRouter(WsList)