import React from 'react'
import BackendCall from '../../com/BackendCall';
import { InfiniteLoaderBar } from '../../components/InfiniteLoaderBar';
import '../../styles/components/TagDisplay.scss'

const TagDisplay = (props) => {
    const {
        onClick,
        filter_by,
    } = props

    const [tags, setTags] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true)
        new BackendCall(
            d => {
                setLoading(false)
                let tagsReady = 
                    d.map(i => i.tag_name) // mapeamos ['tag 1', 'tag 2', 'tag n']
                    .filter(i => i.length > 0) // eliminamos tags con nombres vacios ''
                    .filter(i => i !== filter_by) // eliminamos el tag mas coincidente
                    .splice(0, 10)
                setTags([...tagsReady])
            },
            e => console.error(e)
        ).getTagsEmbedded(filter_by)
    }, [filter_by])

    if (loading) {
        return (
            <div className='tag display'>
                <InfiniteLoaderBar />
            </div>
        )
    }

    return (
        <div className='tag display'>
            {
                tags.map(t => (
                    <button
                        key={t}
                        className='item shadow'
                        onClick={() => onClick(t)}
                    >
                        {t}
                    </button>
                ))
            }
        </div>
    )
}

export default TagDisplay