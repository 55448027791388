import React from 'react'
import Logo from '../../assets/images/logo-gray.png'
import './WsItem.scss'
import getURL from '../../utils/getURL';
import BackendCall from '../../com/BackendCall';

const WsItem = (props) => {
    const {
        title,
        active,
        cover,
        name,
        description,
        children,
        ws_id
    } = props
    const cond_className = active ? 'active' : ''

    const onClick = () => {
        new BackendCall(() => {
            window.location.href = getURL('root-whit-id', { ws_id })
        }).setActiveWorkspace(ws_id)
    }

    return (
        <>
            <div
                title={title ? title : ''}
                className={'ws-item shadow ' + cond_className}
                onClick={onClick}
            >
                <div className="cover">
                    <img src={cover ? cover : Logo} alt="ws_cover" />
                </div>
                <div className='metadata'>
                    <div className="context">
                        <h2>{(name.length > 34) ? name.slice(0, 34) + '...' : name}</h2>
                        <p>{description}</p>
                    </div>
                    {children ? children : null}
                </div>
            </div >
        </>
    )
}

export default WsItem