import BackendCall from '../com/BackendCall';
import NeuralActionsApp from '../stores/NeuralActionsApp';
import getURL from './getURL';
const LoginTools = {}

LoginTools.userIsLoged = () => {
    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d);
    }

    //eslint-disable-next-line
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const localStorageExpToken = window.localStorage.getItem('v1Tkn') ?
        window.localStorage.getItem('v1TknExp') : false;

    const expirationDateToken = isSafari && localStorageExpToken ?
        new Date(localStorageExpToken.replace(/-/g, "/")) :
        new Date(localStorageExpToken)

    const currentDate = new Date();

    if (
        isValidDate(expirationDateToken) &&
        expirationDateToken > currentDate
    ) {
        return true
    }

    return false
}

LoginTools.getUsrID = (cllbk) => {
    if (!LoginTools.userIsLoged()) {
        return null
    }
    const app = NeuralActionsApp.getState().app;

    if (!app.initial_fetch || !app.user_id) {
        new BackendCall(
            d => cllbk(d.usr_id),
            e => console.error(e)
        ).getUsrIDAndEmail()
    } else {
        cllbk(app.user_id)
    }

}

LoginTools.logout = () => {
    var api = new BackendCall(() => {
        localStorage.removeItem('portalUrl');
        localStorage.removeItem('v1Tkn');
        localStorage.removeItem('v1TknExp');
        window.location.href = getURL('login');
    }, () => {
        localStorage.removeItem('portalUrl');
        localStorage.removeItem('v1Tkn');
        localStorage.removeItem('v1TknExp');
        window.location.href = getURL('login');
    })

    api.logout();
}

export default LoginTools