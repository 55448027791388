import ENV from '../env';
import LoginTools from '../utils/LoginTools';

export default class BackendCall {
    constructor(okCallback, failCallback = e => console.error(e)) {
        if (typeof okCallback !== 'function' || typeof failCallback !== 'function') {
            throw new Error("callback parameter must be a function")
        }

        this.callback = okCallback;
        this.failCallback = failCallback;

        this.urlBackend = ENV.URL + '/api/v1/';
    }

    // USER
    unAuhtCall(url, method, parameters, expected_http_code) {
        switch (method) {
            case 'GET':
                fetch(url, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                }).then(response => {
                    if (response.status === parseInt(expected_http_code)) {
                        // Parseamos de json los datos recuperados
                        response.json().then(data => { this.callback(data) })
                    } else {
                        response.json().then(data => { this.failCallback(data) })
                    }
                });
                break;
            case 'DELETE':
                fetch(url, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify(parameters)
                }).then(response => {
                    if (response.status === parseInt(expected_http_code)) {
                        this.callback()
                    } else {
                        response.json().then(data => { this.failCallback(data) })
                    }
                });
                break;
            default:
                fetch(url, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify(parameters),
                    mode: 'cors'
                }).then(response => {
                    if (response.status === parseInt(expected_http_code)) {
                        // Parseamos de json los datos recuperados
                        response.json().then(data => { this.callback(data) })
                    } else {
                        response.json().then(data => { this.failCallback(data) })
                    }
                });
                break;
        }
    }

    auhtCall(url, method, parameters, expected_http_code) {
        const token = localStorage.getItem('v1Tkn');

        switch (method) {
            case 'GET':
                fetch(url, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + token,
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                }).then(response => {
                    if (response.status === parseInt(expected_http_code)) {
                        // Parseamos de json los datos recuperados
                        response.json().then(data => { this.callback(data) })
                    } else {
                        if (response.status === 401) {
                            this.loginOff();
                        }
                        response.json().then(data => { this.failCallback(data) })
                    }
                });
                break;
            case 'DELETE':
                fetch(url, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + token,
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify(parameters)
                }).then(response => {
                    if (response.status === parseInt(expected_http_code)) {
                        this.callback()
                    } else {
                        if (response.status === 401) {
                            this.loginOff();
                        }
                        response.json().then(data => { this.failCallback(data) })
                    }
                });
                break;
            default:
                fetch(url, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + token,
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify(parameters)
                }).then(response => {
                    if (response.status === parseInt(expected_http_code)) {
                        // Parseamos de json los datos recuperados
                        response.json().then(data => { this.callback(data) })
                    } else {
                        if (response.status === 401) {
                            this.loginOff();
                        }
                        response.json().then(data => { this.failCallback(data) })
                    }
                });
                break;
        }
    }

    signin(name, email, password, repassword, lang) {
        const url = this.urlBackend + 'signin';
        const parameters = {
            name: name,
            email: email,
            password: password,
            password_confirmation: repassword,
            lang: lang
        }

        this.unAuhtCall(url, 'POST', parameters, 201);
    }

    login(email, password, remember_me) {
        const url = this.urlBackend + 'login';

        const parameters = {
            email: email,
            password: password,
            remember_me: remember_me,
        }

        this.unAuhtCall(url, 'POST', parameters, 200);
    }

    loginOff() {
        localStorage.removeItem('v1Tkn');
        localStorage.removeItem('v1TknExp');
        // window.location.href = '/'
    }

    logout() {
        const url = this.urlBackend + 'logout';
        this.auhtCall(url, 'GET', [], 200);
    }

    forgotpass(email) {
        const url = this.urlBackend + 'forgotpass/' + email;

        this.unAuhtCall(url, 'GET', [], 200);
    }

    setPass(oldPassword, newPassword, retypeNewPassword) {
        const url = this.urlBackend + 'user/set-pass';
        const parameters = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            retypeNewPassword: retypeNewPassword,
        }
        this.auhtCall(url, 'PUT', parameters, 201);
    }

    activatedEmail(confirm_code) {
        const url = this.urlBackend + 'confirm/' + confirm_code;
        this.unAuhtCall(url, 'GET', [], 200)
    }

    getCover(user_id) {
        const url = this.urlBackend + 'user/' + user_id + '/get-cover'
        this.auhtCall(url, 'GET', [], 200)
    }

    setCover(id = 'unset', src = 'unset') {
        const url = this.urlBackend + 'user/set-cover'
        this.auhtCall(url, 'put', { id, src }, 201)
    }

    getUsrIDAndEmail(id = null, email = null) {
        const url = this.urlBackend + 'user/search/get-mail-id'
        const parameters = { id, email }

        this.auhtCall(url, 'post', parameters, 200)
    }

    setCustomUserConfig(key, value) { // #TODO UNIFICAR FUNCION
        const url = this.urlBackend + 'user/config';

        const parameters = {
            'config': key,
            'value': value
        }

        this.auhtCall(url, 'PUT', parameters, 201);
    }

    setCustomUserProperty(key, value) {
        const url = this.urlBackend + 'user/config';

        const parameters = {
            'config': key,
            'value': value
        }

        this.auhtCall(url, 'PUT', parameters, 201);
    }

    getCustomUserProperty(key) {
        const url = this.urlBackend + 'user/config/' + key;

        this.auhtCall(url, 'GET', {}, 200);
    }

    // WORKSPACES

    getActiveWorkspace() {
        const url = this.urlBackend + 'user/config/active_workspace';
        this.auhtCall(url, 'GET', [], 200);
    }

    workspacesGetAll() {
        const url = this.urlBackend + 'workspaces';

        this.auhtCall(url, 'GET', {}, 200);
    }

    getWorkspace(id) {
        const url = this.urlBackend + 'workspaces/' + id;
        this.auhtCall(url, 'GET', [], 200);
    }

    updateWorkspace(id, name, cover, description, tags, default_search) {
        const url = this.urlBackend + 'workspaces';
        const parameters = {
            'name': name,
            'cover': cover,
            'description': description,
            'id': id,
            'tags': tags,
            'default_search': default_search,
        }

        this.auhtCall(url, 'PUT', parameters, 202);
    }

    createWorkspace(name, cover, description, tags, default_search) {
        const url = this.urlBackend + 'workspaces';
        const parameters = {
            'name': name,
            'cover': cover,
            'description': description,
            'tags': tags,
            'default_search': default_search,
        }

        this.auhtCall(url, 'POST', parameters, 201);
    }

    setActiveWorkspace(id) {
        const url = this.urlBackend + 'user/config';

        const parameters = {
            'config': 'active_workspace',
            'value': id
        }

        this.auhtCall(url, 'PUT', parameters, 201);
    }

    getAllUserOfAWs(ws_id) {
        const url = this.urlBackend + 'workspaces/users'
        const parameters = {
            ws_id: ws_id
        }

        this.auhtCall(url, 'POST', parameters, 200)
    }

    // SEARCHS

    searchNodes(save_as_cluster, raw_search_string, page = 1, active_workspace_id, level = 0, skip_history = false) {
        const url = this.urlBackend + 'network';
        const parameters = {
            'save_as_cluster': save_as_cluster,
            'raw_search_string': raw_search_string,
            'page': page,
            'level': level,
            'workspaces': {
                'active_workspace_id': active_workspace_id
            },
            'skip_history': skip_history
        }

        this.auhtCall(url, 'POST', parameters, 200);
    }

    getLastSearchs() {
        const url = this.urlBackend + 'network';
        this.auhtCall(url, 'GET', [], 200);
    }

    getNodeChild(node_i, workspaces_id) {
        const url = this.urlBackend + 'network/get-child';
        const parameters = {
            'node_id': node_i,
            'workspaces_id': workspaces_id,
        }

        this.auhtCall(url, 'POST', parameters, 200);
    }

    getPathBetween(node_a_id, node_b_id, workspaces_id) {
        const url = this.urlBackend + 'network/path-between';
        const parameters = {
            'node_a': node_a_id,
            'node_b': node_b_id,
            'workspaces_id': workspaces_id,
        }

        this.auhtCall(url, 'POST', parameters, 200)
    }

    // SCHEME

    createScheme(name, fields, active_workspace_id, disabled_for_recommendation) {
        const url = this.urlBackend + 'nodes/scheme';
        const parameters = {
            "name": name,
            "disabled_for_recommendation": disabled_for_recommendation,
            "fields": fields,
            "workspaces": {
                "active_workspace_id": active_workspace_id,
            }
        };
        this.auhtCall(url, 'POST', parameters, 201)
    }

    getAllSchemesOfWs(workspaces_id) {
        const url = this.urlBackend + 'nodes/schemes/' + workspaces_id;
        this.auhtCall(url, 'GET', [], 200);
    }

    getScheme(id) {
        if (typeof id !== 'string') {
            throw new Error('id must be string');
        }

        const url = this.urlBackend + 'nodes/scheme/' + id;
        this.auhtCall(url, 'GET', [], 200);
    }

    updateScheme(id, name, disabled_for_recommendation) {
        const url = this.urlBackend + 'nodes/scheme';
        const parameters = {
            'id': id,
            'name': name,
            'disabled_for_recommendation': disabled_for_recommendation,
        }

        this.auhtCall(url, 'PUT', parameters, 202);
    }

    addSchemeToWs(scheme_id, workspace_id) {
        const url = this.urlBackend + 'nodes/scheme/workspaces'
        const paramenters = {
            scheme_id,
            workspace_id
        }

        this.auhtCall(url, 'POST', paramenters, 202)
    }

    removeSchemeFromWs(scheme_id, workspace_id) {
        const url = this.urlBackend + 'nodes/scheme/workspaces'
        const paramenters = {
            scheme_id,
            workspace_id
        }

        this.auhtCall(url, 'DELETE', paramenters, 204)
    }

    // SCHEME ROLE PARAMS

    createSchemeRoleParams(scheme_id, role_id, config) {
        const url = this.urlBackend + 'nodes/scheme/role';
        const params = {
            scheme_id,
            role_id,
            config
        }

        this.auhtCall(url, 'POST', params, 201)
    }

    getSchemeRoleParams(id) {
        const url = this.urlBackend + 'nodes/scheme/role/' + id
        this.auhtCall(url, 'GET', [], 200)
    }

    updateSchemeRoleParams(id, config) {
        const url = this.urlBackend + 'nodes/scheme/role'
        const params = {
            id,
            config
        }

        this.auhtCall(url, 'PUT', params, 202)
    }

    getSchemeRoleParamsBySchemeID(scheme_id) {
        const url = this.urlBackend + 'nodes/scheme/' + scheme_id + '/roles'

        this.auhtCall(url, 'GET', [], 200)
    }

    deleteSchemeRoleParams(id) {
        const url = this.urlBackend + 'nodes/scheme/role/' + id

        this.auhtCall(url, 'DELETE', [], 204)
    }

    // FIELDS

    createField(name, hard_type, scheme_id, pos, extra_type_values = null) {
        const url = this.urlBackend + 'nodes/scheme/field';
        const parameters = {
            'name': name,
            'hard_type': hard_type,
            'scheme_id': scheme_id,
            'pos': pos,
            'extra_type_values': extra_type_values,
        }

        this.auhtCall(url, 'POST', parameters, 201);
    }

    updateField(id, name, hard_type, pos, extra_type_values = null) {
        const url = this.urlBackend + 'nodes/scheme/field';
        const parameters = {
            'id': id,
            'name': name,
            'hard_type': hard_type,
            'pos': pos,
            'extra_type_values': extra_type_values,
        }

        this.auhtCall(url, 'PUT', parameters, 202);
    }

    deleteField(id) {
        const url = this.urlBackend + 'nodes/scheme/field';
        const parameter = {
            'id': id
        };

        this.auhtCall(url, 'DELETE', parameter, 204);
    }

    // NODES

    createNode(values, workspaces_id) {
        const url = this.urlBackend + 'nodes';
        const parameters = {
            values: values,
            workspaces: {
                active_workspace_id: workspaces_id
            }
        }

        this.auhtCall(url, 'POST', parameters, 201);
    }

    updateNode(id, values) {
        if (typeof id !== 'string' || Array.isArray(values)) {
            throw new Error('Invalid parameters type')
        }
        const url = this.urlBackend + 'nodes';
        const parameters = {
            id: id,
            values: values
        };

        this.auhtCall(url, 'PUT', parameters, 202);
    }

    deleteNode(id) {
        const url = this.urlBackend + 'nodes';

        const parameters = {
            id: id,
        }

        this.auhtCall(url, 'DELETE', parameters, 204);
    }

    chOwnerNode(node_id, new_own_id) {
        const url = this.urlBackend + 'nodes/chown'

        const parameters = { node_id, new_own_id }

        this.auhtCall(url, 'PUT', parameters, 202)
    }

    // EDGES

    deleteEdge(id) {
        const url = this.urlBackend + 'edge';

        const parameters = {
            id: id,
        }

        this.auhtCall(url, 'DELETE', parameters, 204);
    }

    createEdge(node_a_id, node_b_id, workspaces_id, relation_name) {
        const url = this.urlBackend + 'edge';
        const name = relation_name.replace('-', '_GM_')
        const parameters = {
            workspaces: {
                active_workspace_id: workspaces_id
            },
            node_a: node_a_id,
            node_b: node_b_id,
            relation_type: name,
        }

        this.auhtCall(url, 'POST', parameters, 201);
    }

    // SHARE

    shareNode(node_id, workspace_guest_id) {
        const url = this.urlBackend + 'share/link';
        const parameters = {
            node_id: node_id,
            workspace_guest_id: workspace_guest_id
        }

        this.auhtCall(url, 'POST', parameters, 201);
    }

    copyNode(node_id, workspace_guest_id) {
        const url = this.urlBackend + 'share/copy';
        const parameters = {
            node_id: node_id,
            workspace_guest_id: workspace_guest_id
        }

        this.auhtCall(url, 'POST', parameters, 201);
    }

    // ROLES

    createRole(workspaces_id, name, restrictions) {
        const url = this.urlBackend + 'roles';
        const parameters = {
            active_workspace_id: workspaces_id,
            name: name,
            restrictions: restrictions
        }

        this.auhtCall(url, 'POST', parameters, 201);
    }

    updateRole(role_id, name, restrictions) {
        const url = this.urlBackend + 'roles';
        const parameters = {
            id: role_id,
            name: name,
            restrictions: restrictions
        }

        this.auhtCall(url, 'PUT', parameters, 202);
    }

    desactivateRole(role_id) {
        const url = this.urlBackend + 'roles/' + role_id;

        this.auhtCall(url, 'DELETE', {}, 204);
    }

    getRolesOfWS(ws_id) {
        const url = this.urlBackend + 'roles/workspace/' + ws_id

        this.auhtCall(url, 'GET', [], 200)
    }

    // UTILS

    searchUserIdByEmail(email) {
        const url = this.urlBackend + 'user/search/email';
        const parameters = {
            email: email,
        }

        this.auhtCall(url, 'POST', parameters, 200);
    }

    searchUserByPartialEmail(email) {
        const url = this.urlBackend + 'user/search/email/' + email;
        this.auhtCall(url, 'GET', [], 200);
    }

    postFile(file, workspaces_id) {
        const token = localStorage.getItem('v1Tkn');
        const url = this.urlBackend + 'file';
        var data = new FormData();

        data.append('file', file);
        data.append('active_workspace_id', workspaces_id);


        fetch(url, {
            method: 'POST',
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': "Bearer " + token,
                // 'X-Requested-With': 'XMLHttpRequest',
            },
            body: data
        }).then(response => {
            if (response.status === 201) {
                // Parseamos de json los datos recuperados
                response.json().then(data => { this.callback(data) })
            } else {
                response.json().then(data => { this.failCallback(data) })
            }
        });
    }

    // INVITACIONES    

    createInvitation(role_id, guest_id, msj) {
        const url = this.urlBackend + 'roles/invitation';
        const parameters = {
            role_id: role_id,
            guest_id: guest_id,
            msj: msj,
        }

        this.auhtCall(url, 'POST', parameters, 201);
    }

    updateInvitation(id, role_id, msj) {
        const url = this.urlBackend + 'roles/invitation';
        const parameters = {
            role_id: role_id,
            id: id,
            msj: msj,
        }

        this.auhtCall(url, 'PUT', parameters, 202);
    }

    deleteInvitation(id) {
        const url = this.urlBackend + 'roles/invitation/' + id;

        this.auhtCall(url, 'DELETE', {}, 204);
    }

    // ACTIONS

    // SCHEME ACTIONS

    getActionsBySchema(scheme_id) {
        const url = this.urlBackend + 'actions/scheme/' + scheme_id;

        this.auhtCall(url, 'GET', {}, 200)
    }

    // CRFP

    createActionCRFP(scheme_id, workspace_id, field_id, relation_name, new_node_on_fail, scheme_id_new_node, run_only_on_create) {
        const url = this.urlBackend + 'actions/scheme/CRFP';
        const parameters = {
            scheme_id: scheme_id,
            workspace_id: workspace_id,
            field_id: field_id,
            relation_name: relation_name,
            new_node_on_fail: new_node_on_fail,
            scheme_id_new_node: scheme_id_new_node,
            run_only_on_create: run_only_on_create,
        }

        this.auhtCall(url, 'POST', parameters, 201)
    }

    updateActionCRFP(id, field_id, relation_name, new_node_on_fail, scheme_id_new_node, run_only_on_create) {
        const url = this.urlBackend + 'actions/scheme/CRFP';
        const parameters = {
            id: id,
            field_id: field_id,
            relation_name: relation_name,
            new_node_on_fail: new_node_on_fail,
            scheme_id_new_node: scheme_id_new_node,
            run_only_on_create: run_only_on_create,
        }

        this.auhtCall(url, 'PUT', parameters, 202)
    }

    deleteActionCRFP(id) {
        const url = this.urlBackend + 'actions/scheme/CRFP';
        const parameters = {
            id: id,
        }

        this.auhtCall(url, 'DELETE', parameters, 204)
    }

    // TRAKERS

    createActionTracker(
        scheme_id,
        field_id,
        relation_name,
        scheme_id_obj,
        inverse_direction
    ) {
        const parameters = {
            scheme_id,
            field_id,
            relation_name,
            scheme_id_obj,
            inverse_direction
        }
        const url = this.urlBackend + 'actions/scheme/tracker';
        this.auhtCall(url, 'POST', parameters, 201)
    }

    updateActionTracker(
        id,
        field_id,
        relation_name,
        scheme_id_obj,
        inverse_direction
    ) {

        const url = this.urlBackend + 'actions/scheme/tracker';
        const parameters = {
            id,
            field_id,
            relation_name,
            scheme_id_obj,
            inverse_direction
        }

        this.auhtCall(url, 'PUT', parameters, 202)

    }

    deleteActionTracker(id) {
        const url = this.urlBackend + 'actions/scheme/tracker';
        const parameters = {
            id: id,
        }

        this.auhtCall(url, 'DELETE', parameters, 204)
    }

    // TAGS

    getAllTagsOfWs(ws_id) {
        const url = this.urlBackend + 'tags/ws/' + ws_id;
        this.auhtCall(url, 'GET', [], 200);
    }

    getAllTagsOfNode(node_id) {
        const url = this.urlBackend + 'tags/node/' + node_id;

        this.auhtCall(url, 'GET', [], 200);
    }

    createTag(name, workspace_id, hue, nodes) {
        const url = this.urlBackend + 'tags';
        const parameters = {
            name: name,
            workspace_id: workspace_id,
            hue: hue,
            nodes: nodes
        }

        this.auhtCall(url, 'POST', parameters, 201);
    }

    updateTag(id, name, hue, nodes = []) {
        const url = this.urlBackend + 'tags';
        const parameters = {
            id: id,
            name: name,
            hue: hue,
            nodes: nodes
        }

        this.auhtCall(url, 'PUT', parameters, 202);
    }

    deleteNodeFromTag(tag_id, node_id) {
        const url = this.urlBackend + 'tags/node';
        const parameters = {
            tag_id: tag_id,
            node_id: node_id
        }

        this.auhtCall(url, 'DELETE', parameters, 204);
    }

    deleteTag(tag_id) {
        const url = this.urlBackend + 'tags/' + tag_id;

        this.auhtCall(url, 'DELETE', [], 204);
    }

    // EMBEDDED WS

    getEmbeddedWsFromWs(ws_id) {
        const url = this.urlBackend + 'embedded/ws/index/' + ws_id;

        this.auhtCall(url, 'GET', [], 200);
    }

    createEmbeddedWs(
        workspace_id,
        name,
        search_string,
        staticq,
        visualization,
        visualization_flex,
        cover,
        description,
        not_listed,
        tags,
        notify_status
    ) {
        const url = this.urlBackend + 'embedded/ws/';
        const parameters = {
            workspace_id,
            name,
            search_string,
            static: staticq,
            visualization,
            visualization_flex,
            cover,
            description,
            not_listed,
            tags,
            notify_status
        }

        this.auhtCall(url, 'POST', parameters, 201);
    }

    updateEmbeddedWs(
        id,
        name,
        search_string,
        staticq,
        visualization,
        visualization_flex,
        cover,
        description,
        not_listed,
        tags,
        notify_status
    ) {
        const url = this.urlBackend + 'embedded/ws/';
        const parameters = {
            id,
            name,
            search_string,
            static: staticq,
            visualization,
            visualization_flex,
            cover,
            description,
            not_listed,
            tags,
            notify_status
        }

        this.auhtCall(url, 'PUT', parameters, 202);
    }

    deleteEmbeddedWs(id) {
        const url = this.urlBackend + 'embedded/ws/' + id;

        this.auhtCall(url, 'DELETE', [], 204)
    }

    execEmbeddedWs(id, query = null, level = 0) {
        var url = new URL(this.urlBackend + 'embedded/ws/search/' + id);

        const parameters = {
            q: query,
            level: level,
        }

        if (LoginTools.userIsLoged()) {
            LoginTools.getUsrID(id => {
                let _parameters = { ...parameters, u: id }

                Object
                    .entries(_parameters)
                    .filter(i => i[1])
                    .forEach(([name, value]) => url.searchParams.set(name, value))

                this.unAuhtCall(url.href, 'GET', [], 200);
            })
        } else {
            Object
                .entries(parameters)
                .filter(i => i[1])
                .forEach(([name, value]) => url.searchParams.set(name, value))

            this.unAuhtCall(url.href, 'GET', [], 200);
        }

    }

    getNodeFromEmbedded(id) {
        var url = this.urlBackend + 'embedded/node/' + id;

        this.unAuhtCall(url, 'GET', [], 200);
    }

    getGlobalWsPublicationsRecent(page) {
        var url = this.urlBackend + 'embedded/ws/global/recents?p=' + page

        this.auhtCall(url, 'GET', [], 200)
    }

    searchGlobalWsPubliactions(search, page) {
        var url = this.urlBackend + 'embedded/ws/global'
        const parameters = {
            q: search,
            p: page,
        }

        this.auhtCall(url, 'POST', parameters, 200)
    }

    getTagsEmbedded(q = null) {
        var url = this.urlBackend + 'embedded/tags'
        if (q) {
            url = url + '?q=' + q
        }

        this.auhtCall(url, 'GET', [], 200)
    }

    getInternallWsPublicationsRecent(page) {
        var url = this.urlBackend + 'embedded/ws/internall/recents?p=' + page

        this.auhtCall(url, 'GET', [], 200)
    }

    searchInternallWsPublications(search, page) {
        var url = this.urlBackend + 'embedded/ws/internall'
        const parameters = {
            q: search,
            p: page,
        }

        this.auhtCall(url, 'POST', parameters, 200)
    }

    saveEmbeddedWS(id) {
        var url = this.urlBackend + 'embedded/ws/saved/' + id

        this.auhtCall(url, 'GET', [], 201)
    }

    unsaveEmbeddedWS(id) {
        var url = this.urlBackend + 'embedded/ws/saved/' + id

        this.auhtCall(url, 'DELETE', [], 204)
    }

    getSavedEmbeddedWSIndex() {
        var url = this.urlBackend + 'embedded/ws/saved'

        this.auhtCall(url, 'GET', [], 200)
    }

    // EMBEDDED NODES

    createEmbeddedNode(name, workspace_id, node_id, description, cover, not_listed, tags, notify_status) {
        var url = this.urlBackend + 'embedded/node'

        const parameters = {
            name,
            workspace_id,
            node_id,
            description,
            cover,
            not_listed,
            tags,
            notify_status
        }

        this.auhtCall(url, 'POST', parameters, 201)
    }

    updateEmbeddedNode(id, name, description, cover, not_listed, tags, notify_status) {
        var url = this.urlBackend + 'embedded/node'

        const parameters = {
            id,
            name,
            description,
            cover,
            not_listed,
            tags,
            notify_status
        }

        this.auhtCall(url, 'PUT', parameters, 202)
    }

    getEmbeddedNode(id) {
        var url = this.urlBackend + 'embedded/nodes/' + id

        this.auhtCall(url, 'GET', [], 200)
    }

    deleteEmbeddedNode(id) {
        var url = this.urlBackend + 'embedded/nodes/' + id

        this.auhtCall(url, 'DELETE', [], 204)
    }

    getEmbeddedNodesFromWS(ws_id) {
        var url = this.urlBackend + 'embedded/nodes/index/' + ws_id

        this.auhtCall(url, 'GET', [], 200)
    }

    getSavedEmbeddedNodesIndex() {
        var url = this.urlBackend + 'embedded/nodes/saved'

        this.auhtCall(url, 'GET', [], 200)
    }

    saveEmbeddedNode(id) {
        var url = this.urlBackend + 'embedded/nodes/saved/' + id

        this.auhtCall(url, 'GET', [], 200)
    }

    unSaveEmbeddedNode(id) {
        var url = this.urlBackend + 'embedded/nodes/saved/' + id

        this.auhtCall(url, 'DELETE', [], 204)
    }

    getInternallNodesPublicationsRecent(page = 0) {
        var url = this.urlBackend + 'embedded/nodes/internall/recents?p=' + page

        this.auhtCall(url, 'GET', [], 200)
    }

    searchInternallNodesPublications(search, page) {
        var url = this.urlBackend + 'embedded/nodes/internall'
        const parameters = {
            q: search,
            p: page,
        }

        this.auhtCall(url, 'POST', parameters, 200)
    }

    // VIEWS
    /**
     * Obtiene la vista para generar un editor de nodo
     * @param {string} node_id | optional
     */
    NodeEditView(node_id) {
        var url = '';
        if (node_id && typeof node_id === 'string') {
            url = this.urlBackend + 'views/node-edit/' + node_id;
        } else {
            url = this.urlBackend + 'views/node-edit/';
        }

        this.auhtCall(url, 'GET', [], 200);
    }

    LangView(lang) {
        const url = this.urlBackend + 'views/lang/' + lang;

        // eslint-disable-next-line
        const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

        const localStorageExpToken = localStorage.getItem('v1Tkn') ?
            localStorage.getItem('v1TknExp') : false;

        const expirationDateToken = isSafari && localStorageExpToken ?
            new Date(localStorageExpToken.replace(/-/g, "/")) :
            new Date(localStorageExpToken)

        function isValidDate(d) {
            return d instanceof Date && !isNaN(d);
        }

        const currentDate = new Date();

        if (
            window.localStorage.v1Tkn &&
            window.localStorage.v1Tkn.length &&
            isValidDate(expirationDateToken) &&
            expirationDateToken > currentDate
        ) {
            this.auhtCall(url, 'GET', [], 200)
        } else {
            this.unAuhtCall(url, 'GET', [], 200);
        }
    }

    FirstFetch(ws_id = undefined) {
        var url = this.urlBackend + 'views/first-fetch';

        if (ws_id)
            url = url + '?ws_id=' + ws_id

        this.auhtCall(url, 'GET', [], 200);
    }

    userCan(option, extra_option = '') {
        const url = this.urlBackend + 'views/user-can/' + option + '/' + extra_option
        this.auhtCall(url, 'GET', [], 200)
    }

    // RECOMMENDED

    getRecommendedNodesByOwnWeightAsList(node_id, scheme_id) {
        var url = this.urlBackend + 'recommend/nodes/by-weight/list/' + node_id + '/' + scheme_id
        this.auhtCall(url, 'GET', [], 200)
    }

    // Machine Learning services

    getPublicTagsRecommended = (input_text) => {
        const url = ENV.URL + '/ml-services/publication-classifier';
        const parameters = {
            input_text
        }
        this.auhtCall(url, 'POST', parameters, 200);
    }

    getTxtFromAudio = (audio_id) => {
        const url = ENV.URL + '/ml-services/speech2text';
        const parameters = {
            audio_id
        }
        this.auhtCall(url, 'POST', parameters, 200);
    }

    // SUBSCRIPTIONS & PRODUCTS

    getProducts() {
        var url = this.urlBackend + 'products'
        this.auhtCall(url, 'GET', [], 200)
    }

    getUserSubscriptionStatus() {
        var url = this.urlBackend + 'subscriptions'
        this.auhtCall(url, 'GET', [], 200);
    }

    cancelledSubscription(subscription_id) {
        var url = this.urlBackend + 'subscriptions/' + subscription_id
        this.auhtCall(url, 'DELETE', [], 203)
    }

    disableSubscription() {
        var url = this.urlBackend + 'subscriptions/'
        this.auhtCall(url, 'DELETE', [], 203)
    }

    createSubscription(product) {
        var url = this.urlBackend + 'subscriptions/' + product
        this.auhtCall(url, 'GET', [], 200)
    }

    activatedSubscription(subscription_id) {
        var url = this.urlBackend + 'subscriptions/activate/' + subscription_id
        this.auhtCall(url, 'GET', [], 202)
    }

    // CUSTOM SERVICE

    getCustomWidget(token, app_url) {
        var url = ENV.URL + '/custom/widgets'
        this.unAuhtCall(url, 'POST', {
            token, app_url
        }, 200)
    }
}
