import React from 'react'
import LoadingAnimation from './icons/gif/loading.gif';

export const MainLoading = () => {
    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            textAlign: 'center',
            zIndex: '9999',
            position: 'fixed',
            top: '0px',
            left: '0px'
        }}>
            <img alt='loading' src={LoadingAnimation} />
        </div>
    )
}