import React from 'react'
import { withRouter } from 'react-router-dom';
import SmallPopUp from './SmallPopUp';
import { IconCancelBttn } from './icons/jsx/IconCancelBttn';
import getURL from '../utils/getURL';
import '../styles/components/AddNodeBttn.scss';

const YouReachThePlanLimit = function (props) {
    const Lang = window.Lang
    const callback = props.callback;
    const context = props.context;
    const style = (window.screen.width > 599) ?
        {
            height: 'unset',
            width: '600px',
            marginLeft: '-340px',
            padding: '80px 40px',
            boxShadow: '-1px 3px 23px -5px var(--text)',
            textAlign: 'center',
        } : {
            boxShadow: '-1px 3px 23px -5px var(--text)',
            textAlign: 'center',
            width: 'calc(100% - 20px)',
            marginLeft: 'unset',
            left: '0',
            height: 'unset',
            padding: '40px 10px',
            whiteSpace: 'normal',
        }

    var title = '';
    var description = '';
    var button = '';

    switch (context) {
        case 'node':
            title = 'NO_NODES_TITLE';
            description = 'NO_NODES_DESCRIPTION'
            button = 'GET_MORE_NODES'
            break;
        case 'ws':
            title = 'NO_WS_TITLE';
            description = 'NO_WS_DESCRIPTION'
            button = 'GET_MORE_WS'
            break;
        case 'invitations':
            title = 'NO_GUEST_TITLE';
            description = 'NO_GUEST_DESCRIPTION'
            button = 'GET_MORE_GUEST'
            break;
        case 'user_invitations':
            title = 'NO_USER_INVITATIONS_TITLE';
            description = 'NO_USER_INVITATIONS_DESCRIPTION'
            button = 'GET_MORE_USER_INVITATIONS'
            break;
        default:
            throw new Error("context not parameterized")
    }

    return (
        <SmallPopUp
            cancelCallBack={() => callback()}
            style={style}
        >
            <button
                onClick={(e) => {
                    e.preventDefault();
                    callback()
                }}
                style={{
                    top: "13px",
                    position: "absolute",
                    right: "40px",
                    cursor: "pointer"
                }}
            >
                <IconCancelBttn
                    style={{
                        width: "15px",
                    }}
                />
            </button>
            <div
                className="no-more-nodes-popup"
                style={{
                    position: 'relative'
                }}
            >
                <h1>{Lang.lbl(title)}</h1>
                <p>{Lang.lbl(description)}</p>
                <button
                    onClick={() => props.history.push(getURL('config-status'))}
                >{Lang.lbl(button)}</button>
            </div>
        </SmallPopUp>
    )
}

export default withRouter(YouReachThePlanLimit)