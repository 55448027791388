const ENV = {
    URL: window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2],
    PRODUCION: false,
    allowParentsOrigin: [
        'http://localhost:8081', // dev
        'http://34.121.206.83', // colaboring
        'https://app.wecolaboring.com', // colaboring
        'https://bajo.neuralactions.com', // bajo palermo
        'https://app.clubpalermobajo.com.ar', // bajo palermo
        'https://rafaguru.neuralactions.com', // rafa guru
        'https://app.rafa.guru', // rafa guru
        'https://ehealth.neuralactions.com', // neura salud
    ]
};

export default ENV;
