import React from 'react'
import { withRouter } from 'react-router'
import { IconNavConfig } from '../../components/icons/jsx/IconNavConfig';
import getURL from '../../utils/getURL';
import BackendCall from '../../com/BackendCall';
import ThemeSwitch from '../../components/ThemeSwitch';
import CancelIcon from '../../components/icons/jsx/CancelIcon';
import LoginTools from '../../utils/LoginTools';

import '../../styles/common.scss';
import './ConfigApplet.scss'

const ConfigApplet = (props) => {
    const {
        history,
        gbk = 'home'
    } = props

    const [isFree, setFree] = React.useState(false)
    const [fetched, setFetched] = React.useState(false)
    const [isActive, setActive] = React.useState(false)

    React.useEffect(() => {
        new BackendCall(
            d => {
                if (d.subscription || d.product_self === 'vip') {
                    setFree(false)
                } else {
                    setFree(true)
                }
            },
            e => console.error(e)
        ).getUserSubscriptionStatus()
    }, [])

    React.useEffect(() => {
        if (isFree) {
            new BackendCall(d => {
                if (d.ask4Payment === 'disabled') {
                    setFree(false)
                }
                setFetched(true)
            }).getCustomUserProperty('ask4Payment')
        }
    }, [isFree])

    return (
        <div className="cnf-applet flex">
            {isFree && fetched && (
                <button
                    className="w-full button-login border font-bold rounded"
                    onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        history.push(getURL('config-status') + '?gbk=' + gbk)
                    }}
                >{window.Lang.lbl('UPDGRADE_PLAN')}</button>
            )}
            {isActive ? (
                <>
                    <button
                        onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setActive(false)
                        }}
                        className="quad-40-icon"
                        style={{ padding: '9px' }}
                        title={window.Lang.lbl('TOOLTIP_CONFIG')}
                    >
                        <CancelIcon width='22' />
                    </button>
                    <div className='full'>
                        <div className="fllscr"
                            onClick={e => {
                                e.stopPropagation()
                                setActive(false)
                            }}
                        ></div>
                        <div className='float-cnt'>
                            <div className='float'>
                                {/* set profile picture */}
                                <div>
                                    <button
                                        onClick={e => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            history.push(getURL('config-profile-picture') + '?gbk=' + gbk)
                                        }}
                                    >
                                        <p>{window.Lang.lbl('SET_PROFILE_PICTURE')}</p>
                                    </button>
                                </div>
                                {/* set password */}
                                <div>
                                    <button
                                        onClick={e => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            history.push(getURL('config-setpass') + '?gbk=' + gbk)
                                        }}
                                    >
                                        <p>{window.Lang.lbl('SET_NEW_PASSWORD')}</p>
                                    </button>
                                </div>
                                {/* set password */}
                                <div>
                                    <button
                                        onClick={e => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            history.push(getURL('config-status') + '?gbk=' + gbk)
                                        }}
                                    >
                                        <p>{window.Lang.lbl('STATUS_OF_YOUR_ACCOUNT')}</p>
                                    </button>
                                </div>
                                {/* logout */}
                                <div>
                                    <button
                                        onClick={e => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            LoginTools.logout()
                                        }}
                                    >
                                        <p>{window.Lang.lbl('LOGOUT')}</p>
                                    </button>
                                </div>
                                {/* theme switch */}
                                <hr />
                                <div>
                                    <p>{window.Lang.lbl('THEME')}</p>
                                    <ThemeSwitch />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <button
                    onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        // history.push(getURL('config') + '?gbk=home')
                        setActive(true)
                    }}
                    className="quad-40-icon"
                    style={{ cursor: 'pointer' }}
                    title={window.Lang.lbl('TOOLTIP_CONFIG')}
                >
                    <IconNavConfig />
                </button>
            )
            }
        </div >
    )
}

export default withRouter(ConfigApplet)