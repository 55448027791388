import React from 'react'
import { withRouter } from 'react-router'
import WsList from './WsList'
import List from './List'
import { WsAddBttn } from './WsAddBttn'
import BackendCall from '../../com/BackendCall'

const OwnWs = (props) => {
    const {
        active_workspace,
        own = [],
        filter_by,
        history
    } = props

    const [fetched, setFetched] = React.useState(false)
    const [hide, setHide] = React.useState(false)
    const { Lang } = window
    const [nonResult, setNonResult] = React.useState(own.length === 0)



    let active = own.filter(e => e.id === active_workspace)
    let non_active = own.filter(e => e.id !== active_workspace)
    const orderer_own = [...active, ...non_active]

    React.useState(() => {
        new BackendCall(d => {
            if (d.homeShowOwnWS === 'disabled')
                setHide(true)
            setFetched(true)
        }).getCustomUserProperty('homeShowOwnWS')
    })

    if (!fetched || hide) {
        return null
    }

    return (
        <div className="cnt"
            style={(nonResult) ? { minHeight: 'unset' } : {}}
        >
            {nonResult ?
                <>
                    <div className="title"
                        style={{ cursor: 'pointer' }}
                    >{Lang.lbl('NO_PERSONAL_WS_MATCH_THE_SEARCH').replace('FILTER_BY', filter_by)}</div>
                    <List>
                        <WsAddBttn history={history} filter_by={filter_by} />
                    </List>
                </>
                : <div className="title">{Lang.lbl('OWN')}</div>
            }
            <WsList
                active_workspace={active_workspace}
                ws={orderer_own}
                isOwn={true}
                filter_by={filter_by}
                onNonResult={() => setNonResult(true)}
                onResult={() => nonResult && setNonResult(false)}
            />
        </div>
    )
}

export default withRouter(OwnWs)