import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import './index.css';
import './i18n.js';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import NeuralActionsApp from '../src/stores/NeuralActionsApp.js';
import IframeOptions from './components/PostMessages';
import { Helmet } from "react-helmet";
import history from './utils/history';
// import CookiesNotAllowedDetector from './pages/CookiesNotAllowedDetector';

ReactDOM.render(
    <Provider store={NeuralActionsApp}>
        <Helmet>
            <title>NeuralActions</title>
        </Helmet>
        <Router history={history}>
            {/* <CookiesNotAllowedDetector> */}
            <App />
            {/* </CookiesNotAllowedDetector> */}
            <IframeOptions />
        </Router>
    </Provider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
