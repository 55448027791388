import { Component } from 'react';
import BackendCall from '../com/BackendCall';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import getURL from '../utils/getURL.js';
import SchemesTools from '../utils/SchemesTools';

class FirstFetch extends Component {
    render() {
        return (null)
    }

    componentDidMount() {
        if (!this.props.initial_fetch_toggle) {
            const ws_id_from_url = new URL(window.location).searchParams.get('ws_id')

            var api = new BackendCall(
                (d) => {
                    this.setWsInURL(d.active_workspace.id)
                    this.props.update_nodes_from_search(
                        d.search_result.nodes,
                        d.search_result.edges,
                        d.search_result.page,
                        d.search_result.tms
                    );

                    this.props.initial_fetch(
                        d.active_workspace,
                        d.recent_searchs,
                        d.user_id,
                        d.qty_nodes,
                        d.qty_file_byte_used,
                        d.product,
                    )

                    // Si el usuario ha cambiado su idioma desde el login hay que volver a
                    // actualizar el idioma en el backend
                    if (d.user_lang !== localStorage.getItem('lang')) {
                        new BackendCall(
                            () => { },
                            (e) => console.error(e)
                        ).LangView(localStorage.getItem('lang'))
                    }
                },
                e => {
                    console.error(e);
                }
            )

            api.FirstFetch(ws_id_from_url);
        }
    }

    componentDidUpdate() {
        if (this.props.active_workspace) {
            this.setWsInURL(this.props.active_workspace)
            // Recuperamos los esquemas compartidos
            SchemesTools.retrieveAllSharedSchemes()
        }
    }

    setWsInURL = (ws_id) => {
        const neoUrl = new URL(window.location)
        if (neoUrl.searchParams.get('ws_id') === ws_id || neoUrl.pathname !== getURL('root'))
            return false

        neoUrl.searchParams.set('ws_id', ws_id);
        this.props.history.replace(neoUrl.pathname + neoUrl.search)
    }
}

const mapStateToProps = state => {
    return {
        initial_fetch_toggle: state.app.initial_fetch,
        active_workspace: state.workspace_context.active_workspace,
    }
}

const mapDispatchToProps = {
    initial_fetch: (active_workspace, last_searchs, user_id, qty_nodes, qty_file_byte_used, product_restrictions) => {
        return { type: 'INITIAL_FETCH', active_workspace, last_searchs, user_id, qty_nodes, qty_file_byte_used, product_restrictions }
    },
    update_nodes_from_search: (nodes, edges, page, tms) => {
        return { type: 'UPDATE_NODES_FROM_SEARCH', nodes, edges, page, tms }
    },
}


const Contenedor = withRouter(
    connect(mapStateToProps, mapDispatchToProps, undefined, { pure: false })(FirstFetch)
);

export default Contenedor;