import ENV from "../env";

const getURL = (c, param = null) => {
    // commons
    switch (c) {
        case 'login':
            if (param && param.returnUrl)
                return `/login?returnUrl=${param.returnUrl}`
            else
                return `/login`
        case 'quick-login':
            return `/public/quick-login/${param.access_token}/${param.expires_at}/`
        default:
            break;
    }

    // public
    const isPublic = window.location.pathname.includes('public')
    if (isPublic) {
        const visualization = (window.location.pathname.split('/').filter(i => i.length > 0)[3]) ?
            window.location.pathname.split('/').filter(i => i.length > 0)[3] : 'network';
        const embedded_id = (window.location.pathname.split('/').filter(i => i.length > 0)[2]) ?
            window.location.pathname.split('/').filter(i => i.length > 0)[2] : null;

        if (embedded_id === null) {
            window.location.href(getURL('login'));
        }

        // if (!autorized_visualizations.includes(visualization)) {
        //     throw new Error('visualization_non_autorized');
        // }

        switch (c) {
            case 'change-to-network':
                return `/public/ws/${embedded_id}/network`
            case 'change-to-grid':
                return `/public/ws/${embedded_id}/grid`
            case 'root':
                return `/public/ws/${embedded_id}/${visualization}`
            case 'goto-node':
                return `/public/ws/${embedded_id}/${visualization}/node/${param.node_id}`
            case 'search':
                return `/public/ws/${embedded_id}/${visualization}/search`
            default:
                break;
        }
    }

    // private
    const visualization = (window.localStorage.visualization) ? window.localStorage.visualization : 'grid'

    var schemeEditor = null
    switch (c) {
        case 'change-to-network':
            return '/network'
        case 'change-to-grid':
            return '/grid'
        case 'root':
            return `/${visualization}`
        case 'root-whit-id':
            return `/${visualization}?ws_id=${param.ws_id}`
        case `quick-creation`:
            return `/${visualization}/node/quick-creation`
        case `update-node`:
            const id = new URL(window.location.href).searchParams.get('id')
            schemeEditor = new URL(window.location.href).searchParams.get('schemeEditor')
            if (schemeEditor)
                return `/${visualization}/node/${id}?schemeEditor=${schemeEditor}`
            return `/${visualization}/node/${id}`
        case 'new-node':
            return `/${visualization}/node/new-node`
        case 'goto-node':
            return `/${visualization}/node/${param.node_id}`
        case 'edge-creator':
            return `/${visualization}/edge`
        case 'search':
            return `/${visualization}/search`
        case 'schema-index':
            schemeEditor = new URL(window.location.href).searchParams.get('schemeEditor')
            let scheme_id = new URL(window.location.href).searchParams.get('scheme-id')
            if (schemeEditor && scheme_id)
                return `/ws/${param.ws_id}/scheme/?schemeEditor=${schemeEditor}&scheme-id=${scheme_id}`
            return `/ws/${param.ws_id}/scheme/`
        case 'schema-new':
            return `/ws/${param.ws_id}/scheme/new`
        case 'schema-edit':
            return `/ws/${param.ws_id}/scheme/edit/${param.scheme_id}`
        case 'schema-actions':
            return `/ws/${param.ws_id}/scheme/actions/${param.scheme_id}`
        case 'home':
            return `/`
        case 'ws-new':
            return `/ws/new`
        case 'ws-edit':
            return `/ws/${param.ws_id}/edit`
        case 'ws-embedded':
            return `/ws/${param.ws_id}/embedded`
        case 'generate-embedded-link':
            if (window.localStorage.portalUrl && param.forShare)
                return `https://${window.localStorage.portalUrl}/public/ws/${param.embedded_id}`
            return `${ENV.URL}/public/ws/${param.embedded_id}`
        case 'generate-node-embedded-link':
            if (window.localStorage.portalUrl && param.forShare)
                return `https://${window.localStorage.portalUrl}/public/node/${param.id}/${param.node_id}`
            return `${ENV.URL}/public/node/${param.id}/${param.node_id}`
        case 'config':
            return '/config'
        case 'config-setpass':
            return '/setpass'
        case 'config-status':
            return `/config/status`
        case 'config-profile-picture':
            return `/config/profile-picture`
        default:
            throw new Error('url no found');
    }
}
export default getURL;