import React from 'react'
import Logo from '../../assets/images/logo-gray.png'
import BookMarkWidget from './BookMarkWidget'
import LinkIcon from '../../components/icons/jsx/LinkIcon'
import getURL from '../../utils/getURL'
import copyToClipboard from '../../utils/CopyToClipBoard'
import './Item.scss'

const GetLink = (props) => {
    const [isCopied, setIsCopied] = React.useState(false)

    React.useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false)
            }, 1500)
        }
    }, [isCopied])

    return (
        <button
            type='button'
            className='getLink'
            title={window.Lang.lbl('COPY_LINK')}
            onClick={(e) => {
                e.stopPropagation()
                if (props._type === 'embedded-ws') {
                    copyToClipboard(getURL('generate-embedded-link', { embedded_id: props.id, forShare: true }))
                }
                if (props._type === 'embedded-nodes') {
                    copyToClipboard(getURL('generate-node-embedded-link', { id: props.id, node_id: props.node_id, forShare: true }))
                }
                setIsCopied(true)
            }}
        >
            {isCopied ? <p>{window.Lang.lbl('LINK_COPIED')}</p> :
                <LinkIcon width="24px" />}
        </button>
    )
}

const Item = (props) => {
    const {
        title,
        active = false,
        onClick = () => { },
        cover,
        name,
        description,
        children = null,
        saved = null,
        id,
        _type,
        node_id = null
    } = props

    const cond_className = active ? 'active' : ''
    return (
        <div
            title={title ? title : ''}
            className={'item shadow ' + cond_className}
            onClick={onClick}
        >
            {(['embedded-ws', 'embedded-nodes'].includes(_type)) ? (
                <GetLink
                    node_id={node_id}
                    _type={_type}
                    id={id}
                />
            ) : null}
            {(saved !== null) ?
                <BookMarkWidget
                    saved={saved}
                    pub_id={id}
                    _type={_type}
                /> : null}
            <div className="cover">
                <img src={cover ? cover : Logo} alt="ws_cover" />
            </div>
            <div className="context">
                <h2>{(name.length > 34) ? name.slice(0, 34) + '...' : name}</h2>
                <p>{description}</p>
            </div>
            {children ? children : null}
        </div>
    )
}

export default Item