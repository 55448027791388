import React from 'react';

export const IconNavConfig = (props) => {
  return (
        <svg
          width={(props.width) ? props.width : 40}
          height={(props.width) ? props.width : 40}
          viewBox="0 0 10.583333 10.583334"
          version="1.1"
        >
          <path
            style={{
              //fill: context.state.colorIcons,
              fillOpacity: 1,
              strokeWidth: "0.01158665"
            }}
            d="M4.835 8.683c-.059-.061-.143-.23-.29-.587a2.993 2.993 0 0 0-.133-.292 1.472 1.472 0 0 0-.301-.125 2.18 2.18 0 0 0-.234.093c-.414.182-.641.266-.72.266-.027 0-.082-.048-.32-.28a4.804 4.804 0 0 1-.306-.315c-.015-.028-.019-.05-.013-.089.011-.08.093-.301.223-.605a3.66 3.66 0 0 0 .117-.294c0-.024-.102-.272-.12-.29a3.793 3.793 0 0 0-.314-.126c-.363-.136-.567-.23-.611-.283L1.78 5.72v-.836l.042-.04c.056-.052.235-.137.595-.28.163-.066.304-.128.314-.138.02-.02.126-.267.126-.29 0-.007-.059-.142-.131-.3-.185-.4-.25-.585-.232-.645.01-.033.03-.055.338-.36l.268-.264.06.008c.106.014.374.108.743.26a1.3 1.3 0 0 0 .2.07c.032 0 .283-.098.303-.119.007-.007.061-.139.12-.292.13-.34.24-.57.29-.612l.036-.03h.843l.04.035c.055.047.15.237.297.59.065.156.127.293.137.303.022.022.27.126.301.126a2.5 2.5 0 0 0 .246-.1c.398-.175.63-.26.712-.26.024 0 .084.053.288.25.361.352.344.332.344.407 0 .085-.056.246-.21.606-.07.163-.126.308-.126.321 0 .025.097.264.118.29.005.007.147.064.314.126.364.134.569.228.614.282l.031.038v.83l-.036.037c-.06.06-.226.139-.636.302a2.34 2.34 0 0 0-.281.125c-.018.02-.124.27-.124.29 0 .007.06.142.132.3.14.305.208.475.228.57.01.05.01.064-.009.097-.011.021-.15.164-.306.318-.213.209-.293.28-.313.28-.093 0-.353-.09-.784-.267a1.234 1.234 0 0 0-.193-.068 1.5 1.5 0 0 0-.303.12c-.007.007-.07.156-.138.331-.144.365-.222.53-.273.573l-.036.03h-.848zm.772-2.202c.437-.114.782-.453.898-.882a.904.904 0 0 0 .03-.307.9.9 0 0 0-.03-.303 1.252 1.252 0 0 0-.994-.908 1.256 1.256 0 0 0-1.448.97c-.137.672.316 1.325 1.009 1.452.137.025.395.015.535-.022z"
          />
        </svg>
  )
}
