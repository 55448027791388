import { useEffect, useState } from "react";
import LucideDinamicIcon from "../../components/LucideDinamicIcon.js";
import CustomAppConfig from "../../utils/CustomAppConfig";

const Button = ({
  icon,
  labels,
  endpoint
}) => {
  const {
    lang
  } = localStorage

  return (
    <button
      type="button"
      onClick={() => {
        window.location.href = endpoint
      }}
      className="px-4 py-3 flex flex-col justify-center items-center w-6r h-6r"
    >
      <span>
        <LucideDinamicIcon
          name={icon}
        />
      </span>
      <span>
        {labels[lang]}
      </span>
    </button>
  )
}

const Widget = ({
  buttons
}) => {
  return (
    <div className="flex justify-center gap-2 shadow bg-main rounded mzeroauto w-fit mb-half">
      {buttons.map((bttn, i) => (
        <Button
          key={i}
          icon={bttn.icon}
          labels={bttn.labels}
          endpoint={bttn.endpoint}
        />
      ))}
    </div>
  )
}

export default function CustomWidgets() {
  const [app_config, setAppConfig] = useState(null)

  useEffect(() => {
    CustomAppConfig
      .getWidgetConfig()
      .then(d => {
        if (!d)
          return
        const app_url = CustomAppConfig.getAppUrl().replaceAll('.', '_')
        setAppConfig(d[app_url])
      })
      .catch(d => console.error(d))
  }, [])

  if (!app_config)
    return null

  return (
    <>
      {app_config.widgets.map((widget, i) => (
        <Widget
          key={i}
          buttons={widget.buttons}
        />
      ))}
    </>
  )
}