import React, { createContext, useState, useLayoutEffect } from "react";
export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const { localStorage, location } = window
    const myTheme = localStorage.getItem('theme') === null ? "light" : localStorage.getItem('theme')
    const [theme, setTheme] = useState(myTheme); // tema claro por defecto

    // re-escribe el conjunto de variables css en la etiqueta html
    const isPublic = location.pathname.includes('public');

    const applyTheme = themeArr => {
        const root = document.getElementsByTagName('html')[0];
        root.style.cssText = themes[themeArr].join(';');
    }

    const storeTheme = (themeChoice) => {
        setTheme(themeChoice)
        applyTheme(themeChoice)
        localStorage.setItem("theme", themeChoice)
    }

    useLayoutEffect(() => {
        const sTheme = (themeChoice) => {
            setTheme(themeChoice)
            applyTheme(themeChoice)
            localStorage.setItem("theme", themeChoice)
        }

        if (myTheme === 'light' || isPublic) {
            const a = () => sTheme('light')
            a();
        } else {
            const b = () => sTheme('dark')
            b();
        }
        // si cambia el estado de theme, que repinte la app
    }, [theme, myTheme, isPublic, localStorage]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme, storeTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

const themes = {
    'light': [
        '--text: #53535f',
        '--background-board: #e6e6ea',
        '--background-main: #fcfcfc',
        '--shadow-accent: #efeff1',
        '--shadow-accent-alt: #c8c8d0',
        '--color-icon: invert(31%) sepia(8%) saturate(704%) hue-rotate(201deg) brightness(96%) contrast(86%)',
        '--button-border-bar: #e6e6ea',
        '--button-background-bar:#fcfcfc',
        '--search-bar: #efeff1',
        '--item-accent: #ebf4ff',
        '--shadow-topbar: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
        '--border-item: #e6e6ea',
        '--input-accent:#e9d8fd',
        '--new-board:#dfebf6',
        '--current-board: #f7f7f8',
        '--item-new:#ebf4ff',
        '--buton-accent:#7f9cf5',
        '--board-accent:#ebf4ff',
        '--button-active: #e6e6ea',
        '--file-upload-button: #bee3f8',
        '--file-uploader-icons: invert(57%) sepia(7%) saturate(559%) hue-rotate(201deg) brightness(91%) contrast(86%)',
        '--piquito-color: invert(47%) sepia(12%) saturate(2077%) hue-rotate(202deg) brightness(95%) contrast(86%)',
        '--lightbulb-icon-color: invert(47%) sepia(12%) saturate(2077%) hue-rotate(202deg) brightness(95%) contrast(86%)',
        '--shimmer-1:#efeff1',
        '--shimmer-2:#e6e6ea',
        '--text-alt: #fcfcfc',
        '--btn-regular-bg: #D3D3D9',
        '--btn-regular-bg-hov: #C8C8D0',
        '--bg-main-cards: #f7f7f8',
        '--node-card-bg: #FFFFFF',
        '--node-card-title:#222831',
        '--node-card-scheme-name: rgba(0,0,0, 0.6)',
        '--node-card-shadow: rgb(99 99 99 / 69%) 0px 2px 8px 0px',
        '--node-card-shadow-hover:rgba(0, 0, 0, 72%) 0px 18px 50px -10px',
        '--float-rounded-bttn-shadow:rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        '--pagination-grid-color:#2A4365',
        '--pagination-hover-active-color:#63B3ED',
    ],
    'dark': [
        '--text:#eee',
        '--background-board: #e6e6ea',
        '--background-main: #222831',
        '--shadow-accent: #00adb5',
        '--shadow-accent-alt: #c8c8d0',
        '--color-icon: invert(99%) sepia(2%) saturate(354%) hue-rotate(70deg) brightness(114%) contrast(87%)',
        '--button-border-bar: none',
        '--button-background-bar:#393e46',
        '--search-bar: #393e46',
        '--item-accent: #393e46',
        '--shadow-topbar: 0 5px 6px -7px #00adb5',
        '--border-item: #393e46',
        '--input-accent:#393e46',
        '--new-board:#00adb5',
        '--item-new:#393e46',
        '--buton-accent:#00adb5',
        '--board-accent:#00adb5',
        '--button-active: #53535f',
        '--file-upload-button: #00adb5',
        '--file-uploader-icons: invert(0%) sepia(94%) saturate(25%) hue-rotate(86deg) brightness(103%) contrast(107%)',
        '--piquito-color: invert(43%) sepia(75%) saturate(2023%) hue-rotate(151deg) brightness(97%) contrast(101%)',
        '--lightbulb-icon-color: invert(43%) sepia(75%) saturate(2023%) hue-rotate(151deg) brightness(97%) contrast(101%)',
        '--relations-icons: invert(98%) sepia(100%) saturate(0%) hue-rotate(33deg) brightness(103%) contrast(103%)',
        '--btn-regular-bg:#393E46',
        '--btn-regular-bg-hov: #53535F',
        '--bg-main-cards: #18181b',
        '--node-card-bg: #26262c',
        '--node-card-title:#f7f7f8',
        '--node-card-scheme-name:#c8c8d0',
        '--node-card-shadow:#18181b 0px 2px 8px 0px',
        '--node-card-shadow-hover:#1f1f23 0px 4px 12px;',
        '--float-rounded-bttn-shadow:none',
        '--pagination-grid-color:#ebf8ff',
        '--pagination-hover-active-color:#3182ce',
    ]

}
