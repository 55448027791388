import React, { useContext } from "react";
import Switch from "react-switch";
import { ThemeContext } from "../contexts/ThemeContext.js";
import '../styles/common.scss'

const ThemeSwitcher = () => {
  const { theme,  storeTheme } = useContext(ThemeContext);
  const isLight = theme === "light" ;
  const updateTheme = () => ( theme === "light"  ? storeTheme("dark") : storeTheme("light"));
  const chekeado = isLight ? true : false;

  return (
    <div className='flex' >
      <span className="mr-half">
        <p>{window.Lang.lbl('DARK')}</p> 
      </span>
      <label>
        <Switch 
          onChange={updateTheme} 
          checked={chekeado} 
          onColor="#adadb8" //red
          onHandleColor="#53535f"
          offColor="#e6e6ea"
          offHandleColor="#efeff1"
          handleDiameter={15}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={17}
          width={35}
        />
      </label>
        <p className="ml-half">{window.Lang.lbl('LIGHT')}</p> 
    </div>
  );
};

export default ThemeSwitcher;
