import React from 'react'
import LogoLightTheme from '../../assets/images/logo2020-300x243.png'
import LogoDarkTheme from '../../assets/images/logo-whitelines.png'
import BackendCall from '../../com/BackendCall';

const Logo = () => {
    const [image, setImage] = React.useState(window.localStorage.theme === 'light' ? LogoLightTheme : LogoDarkTheme)
    const [fetched, setFetched] = React.useState(false);

    React.useEffect(() => {
        const api = new BackendCall(d => {
            if (d.homeLogo) {
                setImage(d.homeLogo)
            }
            setFetched(true)
        });
        api.getCustomUserProperty('homeLogo')
        window.api = api
    }, [])

    if (fetched)
        return (
            <>
                <img className="logo" src={image} alt="logo-neuralactions" />
            </>
        )
    else return null
}

export default Logo