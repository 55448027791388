import React from 'react';

export const IconCancelBttn = (props) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            id='svg8'
            viewBox='0 0 10.583333 10.583334'
            height={props.width ? props.width : '38'} width={props.width ? props.width : '38'}
            style={(props && props.style) ? props.style : {}}
        >
            <rect id='rect815' width='1.311' height='8.762' x='-0.656' y='3.102' ry='0.604'
                transform='rotate(-45)'
                //fill={context.state.colorIcons} 
                strokeWidth='2.6' strokeLinecap='round'
                paintOrder='markers stroke fill' />
            <rect transform='rotate(-135)' ry='0.604' y='-4.381' x='-8.139' height='8.762'
                width='1.311' id='rect817'
                //fill={context.state.colorIcons} 
                strokeWidth='2.6' strokeLinecap='round'
                paintOrder='markers stroke fill' />
        </svg>
    )
}
