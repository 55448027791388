import BackendCall from "../com/BackendCall"

const CustomAppConfig = {}

CustomAppConfig.isApp = () => {
    return localStorage.portalUrl ? true : false
}

CustomAppConfig.getAppUrl = () => {
    if (CustomAppConfig.isApp)
        return localStorage.portalUrl
    return null
}

CustomAppConfig.getWidgetConfig = () => {
    return new Promise((val, rej) => {
        if (!CustomAppConfig.isApp())
            return rej({})

        new BackendCall(
            d => val(d),
            e => rej(e)
        ).getCustomWidget(
            localStorage.v1Tkn,
            CustomAppConfig.getAppUrl()
        )
    })
}

export default CustomAppConfig