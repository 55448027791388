import React from 'react'
import SmallPopUp from '../../components/SmallPopUp';
import { IconShare } from '../../components/icons/jsx/IconShare';
import getURL from '../../utils/getURL';
import { IconNavConfig } from '../../components/icons/jsx/IconNavConfig';

export const WsToolBar = (props) => {
    const {
        isOwn,
        ws_id,
        role = {},
        history,
    } = props
    const [configActive, setConfigActive] = React.useState(false)
    const Lang = window.Lang
    const sharedIsEnabled = isOwn || (role && role.restrictions.share)

    return (
        <div className="toolbar">
            {(isOwn) ?
                <>
                    <button
                        className="secondary shadow rounded"
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setConfigActive(true);
                        }}
                    >
                        <IconNavConfig width='24' />
                    </button>
                    {(configActive) ? (
                        <SmallPopUp
                            cancelCallBack={() => setConfigActive(false)}
                            style={{
                                position: "absolute",
                                top: "35px",
                                background: "var(--btn-regular-bg-hov)",
                                width: "224px",
                                left: "-14px",
                                padding: "6px",
                                minHeight: "unset",
                                marginLeft: "unset",
                                height: "unset",
                            }}
                        >
                            <div className="float">
                                <button
                                    onClick={ev => {
                                        ev.stopPropagation();
                                        history.push(getURL('ws-edit', { ws_id }));
                                    }}
                                >{Lang.lbl('TOOLTIP_EDIT_WS')}</button>
                                <button
                                    onClick={ev => {
                                        ev.stopPropagation();
                                        history.push(getURL('schema-index', { ws_id }));
                                    }}
                                >{Lang.lbl('TOOLTIP_EDIT_SCHEMAS')}</button>
                            </div>
                        </SmallPopUp >
                    ) : null}
                </>
                : null
            }
            {(sharedIsEnabled) ?
                <button
                    className="secondary shadow rounded"
                    title={Lang.lbl('EMBEDDED_WS')}
                    onClick={ev => {
                        ev.stopPropagation();
                        history.push(getURL('ws-embedded', { ws_id }));
                    }}
                >
                    <IconShare width='24' />
                </button>
                : null
            }
        </div>
    )
}