import React from 'react'
import '../../styles/pages/Home.scss'

const List = (props) => {
    const {
        children
    } = props

    return (
        <div className="list">
            {children}
        </div>
    )
}

export default List