import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SmallPopUp extends Component {
    static propTypes = {
        cancelCallBack: PropTypes.func.isRequired,
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    render() {
        return (
            <div
                key='popup'
                className={(this.props.className) ? this.props.className + ' smll-pop-up cnt' : 'smll-pop-up cnt'}
                style={(this.props.style) ? this.props.style : null}
                ref={this.setWrapperRef}
                id={(this.props.id) ? this.props.id : null}
            >
                {this.props.children}
            </div>
        )
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick, false);
    }

    handleClick = e => {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.props.cancelCallBack();
        }
    }
}
